import React, { useMemo } from 'react'

import { Alert } from '@barracuda-internal/bds-core'

import { formatDate } from 'global/lib/datetime'
import useLicenseBannerLogic, {
  ExpiryStatus,
  ExpiryType
} from 'global/components/lib/layout/licenseBanner/useLicenseBannerLogic'
import { useFormatMessage } from 'global/lib/localization'

import styles from './licenseBannerStyles'

const BASE_I18N_KEY = 'app.expiration_banner'

export const LicenseBanner: React.FC<any> = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [expiry] = useLicenseBannerLogic()

  return useMemo(() => {
    if (!expiry || !expiry?.date || !expiry?.status) {
      return null
    }

    let intlMessage = 'free_trial_expires'
    switch (expiry.status) {
      case ExpiryStatus.expired:
        intlMessage = expiry.type === ExpiryType.freeTrial ? 'free_trial_expired' : 'subscription_expired'
        break
      case ExpiryStatus.warning:
        intlMessage = expiry.type === ExpiryType.freeTrial ? 'free_trial_expires' : 'subscription_expires'
        break
      default:
        intlMessage = 'free_trial_expires'
    }

    return (
      <Alert
        className={classes.root}
        variant="filled"
        severity={expiry.status as any}
        icon={false}
        data-field={`${expiry.status}Banner`}
      >
        {formatMessage(intlMessage, { expiryDate: formatDate(expiry.date) })}
      </Alert>
    )
  }, [classes, expiry, formatMessage])
}

export default LicenseBanner
