import React, { useMemo } from 'react'

import GlobalScheduledReports from 'global/components/features/scheduledReports/ScheduledReports'

import useScheduledReportsLogic from './useScheduledReportsLogic'

const ScheduledReports: React.FC = () => {
  const [scheduledReportsLogic] = useScheduledReportsLogic()
  const {
    handleOpenScheduledReport,
    isLoading,
    onClickEnabledState,
    onClickDelete,
    onClickOpenReport,
    onSearchChange,
    scheduledReportsDrawerConfig,
    schedules,
    searchValue
  } = scheduledReportsLogic

  return useMemo(
    () => (
      <GlobalScheduledReports
        handleOpenScheduledReport={handleOpenScheduledReport}
        isLoading={isLoading}
        onClickEnabledState={onClickEnabledState}
        onClickDelete={onClickDelete}
        onClickOpenReport={onClickOpenReport}
        scheduledReportsDrawerConfig={scheduledReportsDrawerConfig}
        schedules={schedules}
        onSearchChange={onSearchChange}
        searchValue={searchValue}
      />
    ),
    [
      handleOpenScheduledReport,
      isLoading,
      onClickDelete,
      onClickEnabledState,
      onClickOpenReport,
      onSearchChange,
      scheduledReportsDrawerConfig,
      schedules,
      searchValue
    ]
  )
}

export default ScheduledReports
