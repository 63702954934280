import React from 'react'
import { Routes, Route } from 'react-router-dom'

import RedirectRoute from 'global/components/lib/routes/RedirectRoute'

import routesConfig, { UiRoute } from 'fir/lib/routes/routesConfig'
import Layout from 'fir/components/lib/layout/Layout'

import PublicRoute from 'fir/components/lib/routes/PublicRoute'
import PrivateRoute from 'fir/components/lib/routes/PrivateRoute'

import AutomatedWorkflows from 'fir/components/pages/automatedWorkflows/AutomatedWorkflows'
import WorkflowDetails from 'fir/components/pages/automatedWorkflows/workflowDetails/WorkflowDetails'
import InsightsPotentialIncidents from 'fir/components/pages/insights/potentialIncidents/PotentialIncidents'
import InsightsEmailsByRegion from 'fir/components/pages/insights/emailsByRegion/EmailsByRegion'
import InsightsEmailsByRegionCountry from 'fir/components/pages/insights/emailsByRegionCountry/EmailsByRegionCountry'
import Onboarding from 'fir/components/pages/onboarding/Onboarding'
import Remediation from 'fir/components/pages/remediation/Remediation'
import RemediationIncident from 'fir/components/pages/remediation/incidentDetails/IncidentDetails'
import SigninConnect from 'fir/components/pages/signinConnect/SigninConnect'
import SigninSignup from 'fir/components/pages/signup/SigninSignup'
import SigninSignupComplete from 'fir/components/pages/signupComplete/SigninSignupComplete'
import SigninSignupPolicy from 'fir/components/pages/signupPolicy/SigninSignupPolicy'
import Settings from 'fir/components/pages/settings/Settings'
import StartTrial from 'fir/components/pages/startTrial/StartTrial'
import UserReported from 'fir/components/pages/userReported/UserReported'
import LicenseContent from 'fir/components/pages/license/LicenseContent'
import UnifiedReporting from 'fir/components/pages/unifiedReporting/reports/UnifiedReporting'
import ScheduledReports from 'fir/components/pages/unifiedReporting/schedules/ScheduledReports'

import UnregisteredPage from 'fir/components/pages/unregisteredPage/UnregisteredPage'
import MissingRequirement from 'fir/components/pages/missingRequirement/MissingRequirement'
import EmptyReport from 'fir/components/pages/emptyReport/EmptyReport'

type DisableUserCheck = boolean
export type RouteParams = [UiRoute, React.FC<any>, DisableUserCheck?]

const publicRoutes: RouteParams[] = [
  [routesConfig.SIGNIN_SIGNUP, SigninSignup],
  [routesConfig.SIGNUP_COMPLETE, SigninSignupComplete, true],
  [routesConfig.SIGNUP_COMPLETE_POLICY, SigninSignupPolicy, true],
  [routesConfig.SIGNIN_CONNECT, SigninConnect, true],
  [routesConfig.SIGNIN_REMEDIATES, SigninSignupComplete, true]
]

const AppRoutes = (
  <Layout>
    <Routes>
      {publicRoutes
        .filter(routeparams => routeparams[0])
        .map(routeParams => {
          const [routeConfig, Component] = routeParams

          return (
            <Route
              key={routeConfig.id}
              path={routeConfig.path}
              element={<PublicRoute route={routeConfig} Component={Component} />}
            />
          )
        })}

      <Route
        path={routesConfig.AUTOMATED_WORKFLOWS.path}
        element={<PrivateRoute route={routesConfig.AUTOMATED_WORKFLOWS} Component={AutomatedWorkflows} />}
      />
      <Route
        path={routesConfig.AUTOMATED_WORKFLOWS_WORKFLOW.path}
        element={<PrivateRoute route={routesConfig.AUTOMATED_WORKFLOWS_WORKFLOW} Component={WorkflowDetails} />}
      />
      <Route
        path={routesConfig.INSIGHTS_AUTOMATED.path}
        element={<PrivateRoute route={routesConfig.INSIGHTS_AUTOMATED} Component={InsightsPotentialIncidents} />}
      />
      <Route
        path={routesConfig.INSIGHTS_GEOIP.path}
        element={<PrivateRoute route={routesConfig.INSIGHTS_GEOIP} Component={InsightsEmailsByRegion} />}
      />
      <Route
        path={routesConfig.INSIGHTS_GEOIP_COUNTRY.path}
        element={<PrivateRoute route={routesConfig.INSIGHTS_GEOIP_COUNTRY} Component={InsightsEmailsByRegionCountry} />}
      />
      <Route path={routesConfig.INSIGHTS.path} element={<RedirectRoute to={path => `${path}/automated`} />} />
      <Route
        path={routesConfig.MISSING_REQUIREMENTS.path}
        element={<PrivateRoute route={routesConfig.MISSING_REQUIREMENTS} Component={MissingRequirement} />}
      />
      <Route
        path={routesConfig.NEW_INCIDENT.path}
        element={<PrivateRoute route={routesConfig.NEW_INCIDENT} Component={Remediation} />}
      />
      <Route
        path={routesConfig.ONBOARDING.path}
        element={<PrivateRoute route={routesConfig.ONBOARDING} Component={Onboarding} />}
      />
      <Route
        path={routesConfig.REMEDIATION.path}
        element={<PrivateRoute route={routesConfig.REMEDIATION} Component={Remediation} />}
      />
      <Route
        path={routesConfig.REMEDIATION_INCIDENT.path}
        element={<PrivateRoute route={routesConfig.REMEDIATION_INCIDENT} Component={RemediationIncident} />}
      />
      <Route
        path={routesConfig.EMPTY_REPORT.path}
        element={<PrivateRoute route={routesConfig.EMPTY_REPORT} Component={EmptyReport} />}
      />
      <Route
        path={routesConfig.REMEDIATION_INCIDENT_ROOT.path}
        element={<RedirectRoute to={path => path.replace(/\/incident$/, '')} />}
      />
      <Route
        path={routesConfig.SETTINGS.path}
        element={<PrivateRoute route={routesConfig.SETTINGS} Component={Settings} />}
      />
      <Route
        path={routesConfig.START_TRIAL.path}
        element={<PrivateRoute route={routesConfig.START_TRIAL} Component={StartTrial} />}
      />
      <Route
        path={routesConfig.UNIFIED_REPORTING_ROOT.path}
        element={<PrivateRoute route={routesConfig.UNIFIED_REPORTING_ROOT} Component={UnifiedReporting} />}
      />
      <Route
        path={routesConfig.UNIFIED_REPORTING.path}
        element={<PrivateRoute route={routesConfig.UNIFIED_REPORTING} Component={UnifiedReporting} />}
      />
      <Route
        path={routesConfig.SCHEDULED_REPORTS.path}
        element={<PrivateRoute route={routesConfig.SCHEDULED_REPORTS} Component={ScheduledReports} />}
      />
      <Route
        path={routesConfig.USER_REPORTED.path}
        element={<PrivateRoute route={routesConfig.USER_REPORTED} Component={UserReported} />}
      />
      <Route path={routesConfig.USER_REPORTED_ROOT.path} element={<RedirectRoute to={path => `${path}/emails`} />} />
      <Route path={routesConfig.REPORT.path} element={<RedirectRoute to={path => `${path}/remediation`} />} />
      <Route
        path={routesConfig.LICENSE.path}
        element={<PrivateRoute route={routesConfig.LICENSE} Component={LicenseContent} />}
      />
      {/* If route is not registered */}
      <Route path="*" element={<PublicRoute route={routesConfig.SIGNIN_SIGNUP} Component={UnregisteredPage} />} />
    </Routes>
  </Layout>
)

export default AppRoutes
