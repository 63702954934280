import React from 'react'
import PropTypes from 'prop-types'

import { Button, Typography, Grid, Tooltip } from '@barracuda-internal/bds-core'

import Alert, { AlertProps } from 'global/components/lib/alerts/Alert'
import { useFormatMessage } from 'global/lib/localization'

import CreatedIncidentsDashboard, {
  CreatedIncidentsDashboardProps
} from 'fir/components/pages/remediation/dashboards/CreatedIncidentsDashboard'
import CTABanner from 'fir/components/lib/ctaBanner/CTABanner'
import IncidentsTable, { IncidentsTableProps } from 'fir/components/pages/remediation/incidentsTable/IncidentsTable'
import NewIncidentDialog, { NewIncidentDialogProps } from 'fir/components/lib/newIncidentDialog/NewIncidentDialog'
import { REMEDIATION_CTA_COOKIE } from 'fir/components/lib/ctaBanner/useCTABannerLogic'
import remediationLogic from 'fir/components/pages/remediation/RemediationLogic'
import RemediatedThreatsDashboard, { RemediatedThreatsDashboardProps } from './dashboards/RemediatedThreatsDashboard'
import TopAttackedUsersDashboard, { TopAttackedUsersDashboardProps } from './dashboards/TopAttackedUsersDashboard'

import styles from './remediationStyles'

const BASE_I18N_KEY = 'fir.app.remediation'

export interface Props {
  basicIncidentResponse: boolean
  hideCTA: boolean
  incidentTableConfig: IncidentsTableProps
  incidentsCreatedDashboardConfig: CreatedIncidentsDashboardProps
  newIncidentDialogConfig: NewIncidentDialogProps
  openNewIncidentDialog: () => void
  pageAlertConfig: AlertProps
  remediatedThreatsDashboardConfig: RemediatedThreatsDashboardProps
  showIncidentsPageError: boolean
  topUsersDashboardConfig: TopAttackedUsersDashboardProps
}

const Remediation: React.FC<any> = ({
  basicIncidentResponse,
  hideCTA,
  incidentTableConfig,
  incidentsCreatedDashboardConfig,
  newIncidentDialogConfig,
  openNewIncidentDialog,
  pageAlertConfig,
  remediatedThreatsDashboardConfig,
  showIncidentsPageError,
  topUsersDashboardConfig
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const tooltipText = (
    <div>
      <p>{formatMessage('button.tooltip', { b: (txt: any) => <b key={txt}>{txt}</b> })}</p>
    </div>
  )

  const createIncidentButton = (
    <Tooltip interactive title={tooltipText} disableHoverListener={!basicIncidentResponse}>
      <span>
        <Button
          className={classes.createIncidentButton}
          onClick={() => {
            openNewIncidentDialog()
          }}
          color="primary"
          variant="contained"
          data-action="signup"
          disabled={basicIncidentResponse}
        >
          {formatMessage('button.create_incident')}
        </Button>
      </span>
    </Tooltip>
  )

  return (
    <>
      {newIncidentDialogConfig.isOpened && <NewIncidentDialog {...newIncidentDialogConfig} />}
      {!hideCTA && <CTABanner ctaCookie={REMEDIATION_CTA_COOKIE} />}
      <div className={classes.header}>
        {createIncidentButton}
        <div>
          <Typography variant="h4" component="h1">
            {formatMessage('labels.page_title')}
          </Typography>
          <Typography variant="subtitle1">{formatMessage('labels.page_subtitle')}</Typography>
        </div>
      </div>
      {showIncidentsPageError && <Alert {...pageAlertConfig} />}
      <Grid container className={classes.dashboards} direction="row" spacing={2}>
        <Grid xs={4} item>
          <CreatedIncidentsDashboard {...incidentsCreatedDashboardConfig} />
        </Grid>
        <Grid xs={4} item>
          <RemediatedThreatsDashboard {...remediatedThreatsDashboardConfig} />
        </Grid>
        <Grid xs={4} item>
          <TopAttackedUsersDashboard {...topUsersDashboardConfig} />
        </Grid>
      </Grid>
      <IncidentsTable {...incidentTableConfig} />
    </>
  )
}

Remediation.propTypes = {
  basicIncidentResponse: PropTypes.bool.isRequired,
  hideCTA: PropTypes.bool.isRequired,
  incidentTableConfig: PropTypes.shape({
    columnMenuConfig: PropTypes.any.isRequired,
    columnsConfig: PropTypes.any.isRequired,
    gotoIncident: PropTypes.func.isRequired,
    gridData: PropTypes.object.isRequired,
    GRID_COLUMNS: PropTypes.any.isRequired,
    isPageInProgress: PropTypes.bool.isRequired,
    isMoreResultsLoading: PropTypes.bool.isRequired,
    pageConfig: PropTypes.any.isRequired
  }).isRequired,
  incidentsCreatedDashboardConfig: PropTypes.shape({
    chartConfig: PropTypes.any.isRequired,
    chartWrapperConfig: PropTypes.any.isRequired
  }).isRequired,
  newIncidentDialogConfig: PropTypes.any.isRequired,
  openNewIncidentDialog: PropTypes.func.isRequired,
  pageAlertConfig: PropTypes.shape({
    alertContent: PropTypes.string.isRequired,
    closeAction: PropTypes.func.isRequired,
    pageAlert: PropTypes.bool.isRequired,
    showClose: PropTypes.bool.isRequired
  }).isRequired,
  remediatedThreatsDashboardConfig: PropTypes.shape({
    chartConfig: PropTypes.any.isRequired,
    chartWrapperConfig: PropTypes.any.isRequired
  }).isRequired,
  showIncidentsPageError: PropTypes.bool.isRequired,
  topUsersDashboardConfig: PropTypes.shape({
    chartConfig: PropTypes.any.isRequired,
    chartWrapperConfig: PropTypes.any.isRequired
  }).isRequired
}

export default remediationLogic(Remediation)
