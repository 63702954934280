import React, { useMemo } from 'react'

import { Typography } from '@barracuda-internal/bds-core'

import IMAGES from 'global/configs/theme/images.config'

import { useFormatMessage } from 'global/lib/localization'
import useOnboardingLogic, { OnboardingProps } from './useOnboardingLogic'

import styles from './onboardingStyles'

const BASE_I18N_KEY = 'fir.app.onboarding'

const Onboarding: React.FC<OnboardingProps> = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [onboardingLogic] = useOnboardingLogic()

  return useMemo(() => {
    // eslint-disable-next-line no-empty-pattern
    const {} = onboardingLogic

    return (
      <div className={classes.wrapper}>
        <img className={classes.image} alt="bootstrapping emails" src={IMAGES.emailScanning} />
        <Typography className={classes.content} variant="h6" color="inherit">
          {formatMessage('page_title')}
        </Typography>
        <Typography className={classes.content} variant="subtitle1" color="inherit">
          {formatMessage('page_subtitle')}
        </Typography>
      </div>
    )
  }, [classes.content, classes.image, classes.wrapper, formatMessage, onboardingLogic])
}

export default Onboarding
