import React from 'react'
import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { BDSProvider } from '@barracuda-internal/bds-core'

import browserHistory from 'global/lib/routes/browserHistory'
import { CustomBrowserRouter } from 'global/lib/routes/CustomBrowserRouter'
import initLocalization, { Localization } from 'global/lib/localization'
import initStore from 'global/lib/reduxStore'

import Routes from 'sen/components/lib/routes/Routes'
import appReducers from 'sen/redux/reducers'
import MESSAGES_EN from 'sen/configs/i18n/en'

const root = createRoot(document.getElementById('root')!)

export function SENApp(): void {
  const i18nConfig: Localization = initLocalization(MESSAGES_EN)

  return root.render(
    <Provider store={initStore(appReducers)}>
      <IntlProvider locale={i18nConfig.locale} messages={i18nConfig.messages}>
        <BDSProvider useBdsTheme>
          <CustomBrowserRouter history={browserHistory}>{Routes}</CustomBrowserRouter>
        </BDSProvider>
      </IntlProvider>
    </Provider>
  )
}
