import { useMemo, useCallback, useState, useEffect } from 'react'

import { sortBy } from 'lodash'

import { process } from '@progress/kendo-data-query'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'

import useDialogLogic, { DialogProps } from 'global/lib/dialogs/useDialogLogic'
import { HourRanges, DateRangeProps } from 'global/components/lib/dateRangeSelector/DateRangeSelector'
import { isPending, getErrorMessage } from 'global/redux/toolkit/api'
import { SigninByAllCountries } from 'global/types/api/ato'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { BASE_IMG_PATH } from 'global/configs/theme/images.config'
import { useFormatMessage } from 'global/lib/localization'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import useRecipientsAutocompleteLogic, {
  RecipientsAutocompleteConfig,
  SelectedRecipient
} from 'sen/components/lib/autocompletes/identity/recipients/useRecipientsAutocompleteLogic'
import { DialogType, UserRelatedAlert } from 'sen/components/lib/dialogs/signins/useUserRelatedSigninsLogic'
import { Country } from 'sen/components/lib/dialogs/signins/useCountryRelatedSigninsLogic'
import { getSigninsByAllCountries, resetSigninsByAllCountries } from 'sen/redux/features/ato/atoSlice'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { update as updateSigninsByAllCountriesTable } from 'sen/redux/features/dataTables/signinsByAllCountries/signinsByAllCountriesSlice'
import { ChartCountryEmailHeader, getChartCountryEmailHeader } from 'global/components/lib/charts/geoChartHeader'

export interface ModifiedSigninByAllCountries extends SigninByAllCountries {
  flagSrc: string
}

export interface RecipientsSelectorProps {
  selectedRecipient: SelectedRecipient
  config: RecipientsAutocompleteConfig
}

export interface EventHandlers {
  onViewUserSignins: () => void
  onViewCountrySignins: (mixpanelEvent: string) => (country: SigninByAllCountries['country']) => void
  onCloseSigninsDialog: () => void
}

export interface TableConfig {
  isLoaded: boolean
  inProgress: boolean
  tableData: {
    total: number
    data: ModifiedSigninByAllCountries[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  isFlexibleTable: boolean
}

export type SigninDialogProps = DialogProps & {
  dialogType: DialogType | undefined
  alert: UserRelatedAlert
  country: Country
}

export interface ChartConfig {
  header: ChartCountryEmailHeader
  data: [string, number, any][]
}

export type Error = string | undefined

const DATE_SELECTOR_RANGES = [HourRanges['1Hour'], HourRanges['12hours'], HourRanges.lastDay, HourRanges.lastWeek]

const DEFAULT_TIMEFRAME = 30

const BASE_I18N_KEY = 'sen.app.account_takeover.signins'

export default function useSigninsLogic(): [
  RecipientsSelectorProps,
  SigninDialogProps,
  EventHandlers,
  DateRangeProps,
  TableConfig,
  ChartConfig,
  Error
] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { signinsByAllCountriesTable, signinsByAllCountries, inProgress, error, accessTokenId } = useAppSelector(
    _stores => ({
      accessTokenId: _stores.accessToken?.accessToken?.id,
      signinsByAllCountriesTable: _stores.dataTables.signinsByAllCountries,
      signinsByAllCountries: _stores.ato.signinsByAllCountries,
      inProgress: isPending(_stores.ato.getSigninsByAllCountriesApiStatus),
      error: getErrorMessage(_stores.ato.getSigninsByAllCountriesApiStatus)
    })
  )

  const [recipientsAutocompleteConfig, selectedRecipient] = useRecipientsAutocompleteLogic()
  const [isSigninsDialogOpened, toggleSigninsDialog] = useDialogLogic()
  const [selectedRange, setSelectedRange] = useState<number>(2)
  const [signinsDialogType, setSigninsDialogType] = useState<DialogType | undefined>()
  const [selectedCountry, setSelectedCountry] = useState<SigninByAllCountries['country'] | undefined>()

  // init
  useEffect(() => {
    dispatch(getSigninsByAllCountries(timeframe))

    return () => {
      dispatch(resetSigninsByAllCountries())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const timeframe = useMemo(() => {
    return DATE_SELECTOR_RANGES[selectedRange]
  }, [selectedRange])

  // Range is changed
  useEffect(() => {
    dispatch(getSigninsByAllCountries(timeframe))
  }, [dispatch, timeframe])

  const onViewUserSignins = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.ATO_SIGNINS_VIEW_USER_SIGNINS, {
      accessTokenId,
      account: selectedRecipient?.email
    })
    setSigninsDialogType(DialogType.User)
    toggleSigninsDialog()
  }, [accessTokenId, selectedRecipient, toggleSigninsDialog])

  const onViewCountrySignins = useCallback(
    (mixpanelEvent: string) => (country: string) => {
      analyticsLib.trackAppEvent(mixpanelEvent, {
        accessTokenId
      })
      setSigninsDialogType(DialogType.Country)
      setSelectedCountry(country)
      toggleSigninsDialog()
    },
    [accessTokenId, toggleSigninsDialog]
  )

  const onCloseSigninsDialog = useCallback(() => {
    setSigninsDialogType(undefined)
    setSelectedCountry(undefined)
    toggleSigninsDialog()
  }, [toggleSigninsDialog])

  const tableData = useMemo(() => {
    const { skip, take } = signinsByAllCountriesTable

    const { data } = process(
      (signinsByAllCountries?.report?.countries || []).map((report: SigninByAllCountries) => ({
        ...(report && {
          ...report,
          flagSrc: `${BASE_IMG_PATH}flags/${report.country.toLowerCase()}.svg`
        })
      })),
      { skip, take }
    )

    const sortConfig = signinsByAllCountriesTable.sort?.[0]
    const sortedData = sortBy(
      data.filter(report => report.country),
      signinsByAllCountriesTable.SORT_FIELDS[sortConfig.field]
    )

    return {
      data: sortConfig.dir === 'asc' ? sortedData : sortedData.reverse(),
      total: signinsByAllCountries?.report?.countries?.length || 0
    }
  }, [signinsByAllCountries, signinsByAllCountriesTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = signinsByAllCountriesTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total
    }
  }, [tableData, signinsByAllCountriesTable])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !inProgress && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: signinsByAllCountriesTable.sort,
      onSortChange: (e: any) => {
        dispatch(updateSigninsByAllCountriesTable({ sort: e.sort }))
      }
    }
  }, [dispatch, signinsByAllCountriesTable, tableData.total, inProgress])

  // Geochart
  const chartData = useMemo(() => {
    return tableData.data.reduce(
      (all: ChartConfig['data'], { country, signInCount, flagSrc, name }: ModifiedSigninByAllCountries) => {
        const tooltip = `
          <div style="min-width: 150px">
            <img src="${flagSrc}" width="10" height="10" alt="" />
            <strong>${name.replace('of America', '')}</strong>
            <br />
            ${formatMessage('number_of_signins')}: <strong>${signInCount}</strong>
          </div>
        `

        return [...all, [country, signInCount, tooltip]]
      },
      []
    )
  }, [tableData.data, formatMessage])

  const onSetSelectedRange = useCallback(
    (days: number) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.ATO_SIGNINS_SET_SELECTED_RANGE, {
        accessTokenId
      })
      setSelectedRange(days)
    },
    [accessTokenId, setSelectedRange]
  )

  return useMemo(() => {
    return [
      {
        selectedRecipient,
        config: recipientsAutocompleteConfig
      },
      {
        isDialogOpened: isSigninsDialogOpened,
        toggleDialog: toggleSigninsDialog,
        alert: {
          emailAddress: selectedRecipient?.email || '',
          timeframe: DEFAULT_TIMEFRAME
        },
        country: {
          name: selectedCountry || '',
          timeframe: DATE_SELECTOR_RANGES[selectedRange]
        },
        dialogType: signinsDialogType
      },
      {
        onViewUserSignins,
        onViewCountrySignins,
        onCloseSigninsDialog
      },
      {
        ranges: DATE_SELECTOR_RANGES,
        defaultRange: selectedRange,
        onSelectRange: onSetSelectedRange
      },
      {
        isLoaded: !!signinsByAllCountries?.accessTokenId,
        inProgress,
        tableData,
        pageConfig,
        sortConfig,
        columns: signinsByAllCountriesTable.GRID_COLUMNS,
        columnsConfig: signinsByAllCountriesTable.columnsConfig,
        isFlexibleTable: tableData.total < signinsByAllCountriesTable.ITEMS_PER_PAGE
      },
      {
        header: getChartCountryEmailHeader(),
        data: chartData
      },
      error
    ]
  }, [
    inProgress,
    recipientsAutocompleteConfig,
    selectedRecipient,
    isSigninsDialogOpened,
    toggleSigninsDialog,
    onViewUserSignins,
    onCloseSigninsDialog,
    selectedRange,
    onSetSelectedRange,
    tableData,
    signinsByAllCountries,
    pageConfig,
    sortConfig,
    signinsByAllCountriesTable,
    onViewCountrySignins,
    signinsDialogType,
    selectedCountry,
    error,
    chartData
  ])
}
