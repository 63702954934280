import { config } from 'global/lib/config'
import productLib from 'global/lib/product/product'
import { reduxStore } from 'global/lib/reduxStore'

import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'

import routesConfig, { UiRoute } from 'sen/lib/routes/routesConfig'

const CLEANUP_ROUTES: UiRoute['id'][] = [routesConfig.CLEANUP_TABLE.id, routesConfig.CLEANUP.id]

const ENTRY_PATHS = {
  [config.BUNDLES.BUNDLE1]: routesConfig.DASHBOARD,
  [config.BUNDLES.BUNDLE2]: routesConfig.DASHBOARD,
  [config.BUNDLES.DP]: routesConfig.DOMAIN_FRAUD
}

export default async function validateAppForAccessToken(accessTokenId: string, cb?: any) {
  const activePath = reduxStore.getState()?.app?.activePath
  const bundleVersion = productLib.getSentinelSerialBundleForAccessToken(accessTokenId)
  // validate the bundle version for the current path
  if (activePath?.metadata?.bundle && bundleVersion) {
    const isValidRouteForBundle = activePath?.metadata?.bundle.includes(bundleVersion)

    if (!isValidRouteForBundle) {
      ENTRY_PATHS[bundleVersion as any].goto({ reportId: accessTokenId })
    }

    cb(false)
  }

  if (!productLib.hasSentinelProduct(accessTokenId)) {
    if (productLib.hasETSProduct(accessTokenId)) {
      let newHref = `${config.domains.ets}/report/${accessTokenId}`

      if (config.isDev) {
        newHref = newHref.replace('3005', window.location.port)
      }

      window.location.replace(newHref)

      // if navigating to the cleanup page with a broken AT show cleanup page
    } else if (CLEANUP_ROUTES.some(routeId => routeId === activePath?.id)) {
      routesConfig.CLEANUP.goto()

      // else check if we need to show the start trial page
    } else if (productLib.hasForensicsProduct(accessTokenId)) {
      // set the accessToken then go to the start trial page
      await reduxStore.dispatch(setCurrentAccessToken(accessTokenId))
      routesConfig.START_TRIAL.goto()
      // keep the user on the empty report page
    } else {
      cb(false)
    }
  } else if (cb) {
    cb(true)
  }
}
