import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { initSplitio } from 'global/lib/splitio/splitio'
import useUserDataLib from 'global/lib/userData/useUserData'

import { UiRoute } from 'sen/lib/routes/routesConfig'

export interface ValidateRouteProps {
  validateAppForAccessToken: (reportId: string, cb: (isAppRelatedAccessToken: boolean) => void) => void
  route: UiRoute
  splitioCb?: (reportId: string, handleInit: () => void) => void
}

let validatedAccountId: string | undefined
let previousActivePathId: string
export default function ValidateRoute(
  WrappedComponent: React.ComponentType,
  { validateAppForAccessToken, splitioCb, route }: ValidateRouteProps
): React.FC<any> {
  const ReportLogic: React.FC<any> = props => {
    const dispatch = useAppDispatch()
    const { reportId } = useParams()
    const [userDataLib] = useUserDataLib()

    const { accessTokenId, activePathId, user } = useAppSelector((_stores: any) => ({
      accessTokenId: _stores.accessToken.accessToken?.id,
      activePathId: _stores.app.activePath?.id,
      user: _stores.user.data
    }))

    const isRouteChanged = previousActivePathId !== activePathId
    previousActivePathId = activePathId
    const [isAppRelatedAccessToken, setIsAppRelatedAccessToken] = useState<boolean>(!isRouteChanged)
    const [isReportAlreadyInit, setIsReportAlreadyInit] = useState<boolean>(!isRouteChanged)

    const account = userDataLib.getAccountByAccessToken(reportId)

    const isReportRoute = route?.metadata?.isReportRoute

    // init
    useEffect(() => {
      if (!isReportRoute) {
        setIsReportAlreadyInit(true)
      }

      return () => {
        setIsReportAlreadyInit(false)
      }
    }, [isReportRoute])

    const handleInit = useCallback(() => {
      if (isReportRoute && !isReportAlreadyInit && user.id && accessTokenId === reportId) {
        setIsReportAlreadyInit(true)
      }
    }, [accessTokenId, user.id, isReportAlreadyInit, isReportRoute, reportId])

    const checkSplitioRoles = useCallback(() => {
      if (reportId) {
        dispatch(
          initSplitio({
            accessTokenId: reportId,
            account,
            cb: () => {
              if (splitioCb) {
                splitioCb(reportId, handleInit)
              } else {
                handleInit()
              }
            }
          }) as any
        )
      }
    }, [account, handleInit, dispatch, reportId])

    // new accessTokenId, let's start to check
    useEffect(() => {
      if (accessTokenId === reportId) {
        // validate splitio roles if new account
        if (validatedAccountId !== account?.accountId) {
          validatedAccountId = account?.accountId
          checkSplitioRoles()
          // otherwise render content
        } else {
          setIsReportAlreadyInit(true)
        }
      }

      // validate accessToken
      if (reportId) {
        validateAppForAccessToken(reportId, (isValidAccessToken: boolean) => {
          setIsAppRelatedAccessToken(isValidAccessToken)
        })
        // empty report
      } else {
        setIsAppRelatedAccessToken(true)
        setIsReportAlreadyInit(true)
      }
    }, [accessTokenId, handleInit, checkSplitioRoles, account, reportId, dispatch, userDataLib])

    // render
    return useMemo(() => {
      if (!isReportAlreadyInit || !isAppRelatedAccessToken) {
        return <LinearProgress />
      }

      // Render only the component if user data is loaded and app related scan
      return <WrappedComponent {...props} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReportAlreadyInit, isAppRelatedAccessToken])
  }

  return ReportLogic
}
