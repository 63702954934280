import React, { Children, cloneElement, useMemo } from 'react'

import deepmerge from 'deepmerge'

import { Grid } from '@barracuda-internal/bds-core'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes/baseMui'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import theme from 'global/theme'

import LicenseBanner from 'global/components/lib/layout/licenseBanner/LicenseBanner'
import uselayoutLogic from 'global/components/lib/layout/useLayoutLogic'
import MaintenanceBanner from 'global/components/lib/layout/maintenanceBanner/MaintenanceBanner'
import { navbarHeight } from 'global/components/lib/layout/navbar/navbarStyles'
import PermissionBanner from 'global/components/lib/layout/permissionBanner/PermissionBanner'
import FeatureBanner from 'global/components/lib/layout/featureBanner/FeatureBanner'

import 'global/types/mui/index'

export type GlobalLayoutProps = {
  children: any
  isLicenseBannerEnabled?: boolean
  isMaintenanceBannerEnabled?: boolean
  isNewLayout?: boolean
  isPermissionBannerEnabled?: boolean
  isUnifiedReportingBannerEnabled?: boolean
  navbar?: JSX.Element
}

// TODO: Import theme directly when BDS dependency is updated with theme fix
function Layout({
  children,
  navbar,
  isLicenseBannerEnabled = true,
  isMaintenanceBannerEnabled = false,
  isNewLayout = false,
  isPermissionBannerEnabled = false,
  isUnifiedReportingBannerEnabled = false,
  ...rest
}: GlobalLayoutProps): JSX.Element {
  const appTheme = deepmerge(bdsTheme.default, theme)
  const [layoutConfig] = uselayoutLogic()
  const { isLoggedOut, isNavBarVisible } = layoutConfig

  return useMemo(() => {
    // This is for the new Unified Nav
    // TODO: Import theme directly when BDS dependency is updated with theme fix
    if (isNewLayout) {
      return (
        <MuiThemeProvider theme={createTheme(appTheme)}>
          <CssBaseline />
          <Grid container className="App" style={{ flexWrap: 'nowrap', height: '100vh', width: '100vw' }}>
            {/* TODO: Add Masthead and Banners (incl. NavbarImpersonationBanner) */}
            <div style={{ zIndex: 9999, height: '100vh', position: 'fixed' }}>
              {!isLoggedOut && isNavBarVisible && navbar}
            </div>
            <div className="content" style={{ paddingTop: navbarHeight, paddingLeft: '50px' }}>
              {children}
            </div>
          </Grid>
        </MuiThemeProvider>
      )
    }

    return (
      <MuiThemeProvider theme={createTheme(appTheme)}>
        <CssBaseline />
        <div className="App">
          {!isLoggedOut && navbar}
          <div className="content" style={{ paddingTop: navbarHeight }}>
            {!isLoggedOut && isLicenseBannerEnabled && <LicenseBanner />}
            {!isLoggedOut && isMaintenanceBannerEnabled && <MaintenanceBanner />}
            {!isLoggedOut && isPermissionBannerEnabled && <PermissionBanner />}
            {!isLoggedOut && isUnifiedReportingBannerEnabled && <FeatureBanner />}
            {Children.map(children, child => cloneElement(child as React.ReactElement<any>, { ...rest }))}
          </div>
        </div>
      </MuiThemeProvider>
    )
  }, [
    appTheme,
    children,
    isLicenseBannerEnabled,
    isLoggedOut,
    isMaintenanceBannerEnabled,
    isNavBarVisible,
    isNewLayout,
    isPermissionBannerEnabled,
    isUnifiedReportingBannerEnabled,
    navbar,
    rest
  ])
}

export default Layout
