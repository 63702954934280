import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { DateTime } from 'luxon'

import { Alert, AlertTitle, Button, Collapse, Link } from '@barracuda-internal/bds-core'
import { Announcement } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

import styles from './featureBannerStyles'

const I18N_KEY = 'app.feature_banner'

const FeatureBanner: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(I18N_KEY)

  const [cookies, setCookie] = useCookies(['unified-reporting-banner-dismissed'])
  const [isOpen, setIsOpen] = useState(cookies['unified-reporting-banner-dismissed'] !== 'true')

  // Set a session cookie if it doesn't exist
  useEffect(() => {
    if (!cookies['unified-reporting-banner-dismissed']) {
      setCookie('unified-reporting-banner-dismissed', false, {
        path: '/'
      })
    }
  }, [cookies, setCookie])

  const handleDismiss = useCallback(() => {
    setIsOpen(false)
    setCookie('unified-reporting-banner-dismissed', true, {
      path: '/',
      expires: DateTime.now()
        .plus({ day: 1 })
        .toJSDate()
    })
  }, [setCookie])

  return useMemo(
    () => (
      <Collapse in={isOpen}>
        <Alert
          severity="info"
          className={classes.alert}
          icon={<Announcement fontSize="inherit" />}
          action={
            <Button color="inherit" size="medium" onClick={handleDismiss}>
              {formatMessage('dismiss')}
            </Button>
          }
        >
          <AlertTitle>{formatMessage('title')}</AlertTitle>
          {formatMessage('subtitle', {
            a: (txt: any) => (
              <Link key="link" target="_blank" href={config.LINKS.CAMPUS_UNIFIED_REPORTING}>
                {txt}
              </Link>
            )
          })}
        </Alert>
      </Collapse>
    ),
    [classes.alert, formatMessage, handleDismiss, isOpen]
  )
}

export default FeatureBanner
