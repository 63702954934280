export interface NewIncident {
  createdBy: string
  incidentDetails: IncidentDetails
  labels: string[]
  notificationDetails: NotificationDetails
  remediationActions: RemediationActions
  searchCriteria: SearchCriteria
  senderPolicyDetails: SenderPolicyDetails
  summaryDetails: SummaryDetails
}

export interface Threat {
  intentId: string
  intentPath: string
  intentType: string
}

export interface IncidentDetails {
  threats?: Threat[]
  subSource?: string
  source: string
  sourceEmailId: string | null
  sourceDomainId: string | null
}

export interface NotificationDetails {
  template: 'Default' | 'Custom'
}

export interface RemediationActions {
  quarantine: boolean
  delete: boolean
  notify: boolean
  addSenderPolicy: boolean
  addDomainPolicy: boolean
  addBCSPolicy: boolean
  sendSummary: boolean
  queueInsights?: boolean
  updateMessageLog?: boolean
  enableContinuousRemediation?: boolean
}

export interface SearchCriteria {
  dslQuery?: string
  includeSent?: boolean
  searchId?: string
  attachmentName: string
  emailSubject: string
  includeQuarantined: boolean
  sender: Sender
  subjectContainsPhrase: boolean
  timeframe: number
}

export interface Sender {
  displayName: string
  email: string
  department?: string
  firstName: string
  id: string
  lastName: string
  risks?: string
  source?: string
  spScore?: number
  title?: string
}

export interface SenderPolicyDetails {
  policy: string
  policyComment?: string
}

export interface SummaryDetails {
  timezone: string
}

export enum IncidentDetailsSource {
  incident = 'Incident',
  region = 'Region',
  insights = 'Insights',
  potentialIncidents = 'Potential-Incidents',
  autoRemediation = 'Insights-Automated',
  userReported = 'User-Reported',
  ess = 'ESS',
  sentinel = 'Sentinel',
  publicApi = 'Public-Api',
  automatedWorkflow = 'Automated-Workflow'
}
