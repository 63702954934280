import { useMemo } from 'react'

import { useSelector } from 'react-redux'

export interface LayoutLogic {
  isLoggedOut: boolean
  isNavBarVisible: boolean
}

export default function useLayoutLogic(): [LayoutLogic] {
  const { isLoggedOut, isNavBarVisible }: any = useSelector((_stores: any) => ({
    isLoggedOut: _stores.app.justLoggedOut,
    isNavBarVisible: _stores.app.activePath.isNavbarVisible
  }))

  return useMemo(
    () => [
      {
        isLoggedOut,
        isNavBarVisible
      }
    ],
    [isLoggedOut, isNavBarVisible]
  )
}
