import { makeStyles } from '@material-ui/core/styles'

export const potentialIncidentsSettingsStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  label: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  subFormControl: {
    marginLeft: theme.spacing(4),
    paddingTop: 5
  },
  subContainer: {
    marginTop: theme.spacing(1)
  },
  formLine: {
    marginBottom: theme.spacing(3),
    width: '80%'
  },
  iconPadding: {
    paddingRight: theme.spacing(1)
  },
  inlineTextField: {
    width: '100%'
  },
  matchExactFrame: {
    marginLeft: theme.spacing(1),
    marginRight: 0,
    marginTop: 18
  },
  policyPlaceSelector: {
    marginLeft: '4px',
    '& .MuiSelect-root': {
      fontWeight: 600
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.barracudaBlack70
    }
  },
  mdTextField: {
    width: '50%'
  },
  radioButtonGroup: {
    marginLeft: theme.spacing(2)
  },
  smTextField: {
    width: '10%'
  },
  wordWrap: {
    wordWrap: 'break-word'
  }
}))

export default makeStyles(theme => ({
  wrapper: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  dialogWrapper: {
    marginTop: theme.spacing(5)
  },
  dialogButtons: {
    marginTop: theme.spacing(3),
    paddingRight: 0
  },
  dialogBgColor: {
    backgroundColor: 'white'
  },
  subDialogButtons: {
    float: 'right',
    '& Button': {
      margin: theme.spacing(1)
    }
  },
  emailAlertButton: {
    fontSize: '15px',
    textTransform: 'none'
  },
  emailDetailDialog: {
    padding: theme.spacing(2)
  },
  disabledLabel: {
    color: '#aaaaaa'
  },
  opacity: {
    opacity: 0.5
  },
  inline: {
    display: 'inline-block'
  },
  closerToTop: {
    marginTop: 0
  },
  subSetting: {
    paddingLeft: '20px'
  },
  splitSettings: {
    marginTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[300]}`
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4,
    cursor: 'not-allowed'
  },
  bold: {
    fontWeight: theme.font.weight.bold
  },
  alert: {
    marginBottom: '10px'
  },
  textLowercase: {
    textTransform: 'lowercase'
  },
  adjustHeight: {
    height: 4
  },
  invisibleIcon: {
    color: 'white'
  }
}))
