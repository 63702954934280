import React, { useCallback, useEffect, useMemo, useReducer } from 'react'

import { useParams } from 'react-router-dom'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import * as datetime from 'global/lib/datetime'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'
import useUserDataLib from 'global/lib/userData/useUserData'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import useFeatureLib from 'global/lib/feature/useFeature'

import { IncidentTag, RemediationActions } from 'global/types/api/remediation'

import { AlertProps } from 'global/components/lib/alerts/Alert'
import { TabsProps } from 'global/components/lib/Tabs/Tabs'
import { TagInputProps } from 'global/components/lib/tagInput/TagInput'

import { AdditionalActionsDialogProps } from 'fir/components/lib/additionalActionsDialog/AdditionalActionsDialog'
import routesConfig from 'fir/lib/routes/routesConfig'
import {
  forensicsCreateIncidentTags,
  forensicsDeleteIncidentTag,
  forensicsGetIncident,
  forensicsGetIncidentsTags,
  forensicsRestartContinuousRemediation,
  forensicsStopContinuousRemediation,
  forensicsResetIncident,
  forensicsResetIncidentDeleteExistingEmails,
  resetContinuousRemediation
} from 'fir/redux/features/remediation/remediationSlice'
import { getSecurityTrainingInstances } from 'fir/redux/features/securityTraining/securityTrainingSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { TAG_VALIDATOR } from 'fir/redux/types/Remediation'

import { getErrorMessage, isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'
import { setCurrentBccAccount } from 'global/redux/features/accessToken/accessTokenSlice'

import styles from './IncidentDetailsStyles'

export interface IncidentDetailsProps {
  additionalActionsDialogConfig: AdditionalActionsDialogProps
  breadcrumbList: any
  handleContinuousRemediationChange: any
  hasRemediationActions: boolean
  incidentDetailsData: IncidentDetailsData
  isContinuousRemediationEnabled: boolean
  isContinuousRemediationLoading: boolean
  isDeleteInProgress: boolean
  isPageInProgress: boolean
  onViewWorkflowDetails: (workflowId: string) => void
  openFollowUpDialog: any
  pageAlertConfig: AlertProps
  pageError: string
  showDemoError?: boolean
  tabsConfig: TabsProps
  tagInputConfig: TagInputProps
}

export interface UrlParams {
  accessToken?: string
  incidentId?: string
}

export const CLICKED_LINKS = 'clicked_links'
export const EMAILS = 'emails'
export const USERS = 'users'
export const TASK_STATUS_COMPLETE = 'Completed'
export const THREATS = 'threats'

const BASE_I18N_KEY = 'fir.app.remediation.incident_details'
const GLOBAL_BASE_I18N_KEY = 'app.error'

const AUTOMATIC_REMEDIATION = 'Automatic Remediation'
const TABS = [
  {
    id: EMAILS
  },
  {
    id: USERS
  },
  {
    id: CLICKED_LINKS
  },
  {
    id: THREATS
  }
]
const REMEDIATION_ACTIONS = ['addBCSPolicy', 'addDomainPolicy', 'addSenderPolicy', 'delete', 'notify', 'quarantine']

export interface IncidentDetailsData {
  attachment?: string
  continuousRemediationUntil?: string
  createdBy?: string
  createdByName?: string
  senderEmail?: string
  senderName?: string
  bodyText?: string
  bodyLinks?: string
  subject?: string
  tags?: IncidentTag[]
  continuousRemediationCount: number
  continuousRemediationEnabled: boolean
  createdOn: string
  distinctExternalRecipientCount: number
  distinctInternalRecipientCount: number
  incidentDetails: { source: string; subSource: string }
  isAutomaticRemediation: boolean
  maliciousDomains: string[]
  messagesReceived: number
  notifiedEmailCount: number
  remediatedEmailCount: number
  remediationActions: RemediationActions
  senderPolicies: { blocked: string[]; quarantined: string[] }
  taskStatuses: { clickedLinks: string }
}

export default function useIncidentDetailsLogic(): [IncidentDetailsProps] {
  const classes = styles()
  const urlParams: UrlParams = useParams()
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const globalFormatMessage = useFormatMessage(GLOBAL_BASE_I18N_KEY)
  const [featureLib] = useFeatureLib()
  const [userDataLib] = useUserDataLib()
  const [isAdditionalActionsDialogOpened, toggleAdditionalActionsDialog] = useDialogLogic()

  const userEssRegion = userDataLib.getUser().essRegion

  const {
    accessTokenId,
    bccAccountId,
    continuousRemediationErrorMsg,
    incident,
    isContinuousRemediationLoading,
    isDeleteExistingEmailsSuccess,
    isIncidentLoading,
    tags,
    userEmail
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    bccAccountId: _stores.accessToken.bccAccount || '',
    continuousRemediationErrorMsg: getErrorMessage(_stores.remediation.continuousRemediationApiStatus),
    incident: _stores.remediation.incident,
    isContinuousRemediationFailed: isFailed(_stores.remediation.continuousRemediationApiStatus),
    isContinuousRemediationLoading: isPending(_stores.remediation.continuousRemediationApiStatus),
    isDeleteExistingEmailsSuccess: isSuccess(_stores.remediation.deleteExistingEmailsApiStatus),
    isIncidentLoading: isPending(_stores.remediation.getIncidentApiStatus),
    tags: _stores.remediation.tags,
    userEmail: _stores.user.data.email
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    activeTab: EMAILS,
    isDeleteInProgress: false,
    pageError: '',
    settingsDialog: null
  })

  // Init
  useEffect(() => {
    if (!bccAccountId) {
      const validatedToken = userDataLib.getAccountByAccessToken(accessTokenId)
      if (validatedToken) {
        dispatch(setCurrentBccAccount(validatedToken.bccId as string))
      }
    }
    dispatch(forensicsGetIncident({ accessTokenId, incidentId: urlParams.incidentId as string }))
    dispatch(forensicsGetIncidentsTags({ accessTokenId: urlParams.accessToken as string }))
    dispatch(getSecurityTrainingInstances({ accessTokenId, bccAccountId, userEmail }))

    // unmount
    return () => {
      dispatch(forensicsResetIncident())
      dispatch(resetContinuousRemediation())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bccAccountId])

  useEffect(() => {
    if (continuousRemediationErrorMsg === 'default') {
      setState({ pageError: formatMessage('labels.continuous_remediation_start_error') })
    } else if (continuousRemediationErrorMsg) {
      setState({ pageError: globalFormatMessage(continuousRemediationErrorMsg) })
    }
  }, [continuousRemediationErrorMsg, formatMessage, globalFormatMessage])

  // refresh incident if delete existing incident succeeds
  useEffect(() => {
    if (isDeleteExistingEmailsSuccess && !incident.remediationActions?.delete) {
      dispatch(forensicsGetIncident({ accessTokenId, incidentId: urlParams.incidentId as string }))
      dispatch(forensicsResetIncidentDeleteExistingEmails())
      setState({ isDeleteInProgress: true })
    }
  }, [
    accessTokenId,
    dispatch,
    incident.remediationActions,
    isDeleteExistingEmailsSuccess,
    state.isDeleteInProgress,
    urlParams.incidentId
  ])

  // breadcrumb
  const breadcrumbList = useMemo(() => {
    return [
      {
        text: formatMessage('incidents'),
        key: 'incident-breadcrumb',
        onClick: routesConfig.REMEDIATION.goto
      },
      {
        jsx: (
          <>
            {incident && (
              <>
                Email
                {incident.sender && (incident.sender.displayName || incident.sender.email) && (
                  <>
                    {' '}
                    from{' '}
                    <span key="breadcrumb-sender" className={classes.bold}>
                      {incident.sender.displayName} {incident.sender.email && `<${incident.sender.email}>`}
                    </span>
                  </>
                )}
                {incident.subject && (
                  <>
                    {' '}
                    with subject{' '}
                    <span key="breadcrumb-subject" className={classes.bold}>
                      {incident.subject}
                    </span>
                  </>
                )}
                {incident.attachmentName && (
                  <>
                    {' '}
                    with attachment name{' '}
                    <span key="breadcrumb-attachment" className={classes.bold}>
                      {incident.attachmentName}
                    </span>
                  </>
                )}
              </>
            )}
          </>
        )
      }
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incident])

  const currentIncidentTags = useMemo(() => {
    return incident.labels?.map((tag: IncidentTag) => tag.name) || []
  }, [incident.labels])

  // autocomplete should only show tags that aren't already associated with the incident
  const existingUnusedTags = useMemo(() => {
    return tags
      .filter((tag: IncidentTag) => {
        return !incident.labels?.some((incidentTag: IncidentTag) => tag.name === incidentTag.name)
      })
      .map((tag: IncidentTag) => tag.name)
  }, [incident.labels, tags])

  const handleContinuousRemediationChange = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.CONTINUOUS_REMEDIATION_STATUS, {
      accessToken: accessTokenId,
      incidentId: urlParams.incidentId,
      continuousRemediationEnabled: !incident.continuousRemediationEnabled ? 'ON' : 'OFF'
    })
    if (incident.continuousRemediationEnabled) {
      dispatch(forensicsStopContinuousRemediation({ accessTokenId, incidentId: urlParams.incidentId as string }))
    } else {
      dispatch(forensicsRestartContinuousRemediation({ accessTokenId, incidentId: urlParams.incidentId as string }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incident.continuousRemediationEnabled])

  // TODO: move to global tag input component
  const handleTagChange = useCallback(
    (tagsList: string[]) => {
      const tagsToAdd = tagsList.filter(
        (tag: string) => !incident.labels?.some((incidentTag: IncidentTag) => tag === incidentTag.name)
      )
      const tagToDelete = incident.labels?.find(
        (incidentTag: IncidentTag) => !tagsList.some((tag: string) => tag === incidentTag.name)
      )
      if (tagsToAdd.length) {
        const invalidTags = tagsToAdd.filter((tag: string) => !TAG_VALIDATOR.test(tag))
        if (invalidTags.length) {
          setState({ pageError: formatMessage('labels.tagInvalid') })
        } else {
          dispatch(forensicsCreateIncidentTags({ incidentId: incident.id, tags: tagsToAdd, accessTokenId }))
        }
      }
      if (tagToDelete) {
        dispatch(
          forensicsDeleteIncidentTag({
            accessTokenId,
            incidentId: incident.id,
            tagId: tagToDelete.id,
            tags: incident.labels as IncidentTag[]
          })
        )
      }
    },
    [accessTokenId, dispatch, formatMessage, incident.id, incident.labels]
  )

  const hasRemediationActions = useMemo(() => {
    const remediationActions = incident.remediationActions as RemediationActions

    if (incident.remediationActions) {
      return REMEDIATION_ACTIONS.filter(action => remediationActions[action as keyof RemediationActions]).length > 0
    }
    return false
  }, [incident.remediationActions])

  const incidentDetailsData = useMemo(() => {
    if (!incident) {
      return {} as IncidentDetailsData
    }

    return {
      attachment: incident.attachmentName,
      continuousRemediationCount: incident.continuousRemediationCount,
      continuousRemediationEnabled:
        incident.continuousRemediationUntil && new Date(incident.continuousRemediationUntil) > new Date(),
      continuousRemediationUntil: datetime.formatDate(
        incident.continuousRemediationUntil as string,
        config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT
      ),
      createdOn: datetime.formatDate(incident.created, config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT),
      createdBy: incident.createdBy,
      createdByName: incident.createdByName,
      distinctExternalRecipientCount: incident.distinctExternalRecipientCount,
      distinctInternalRecipientCount: incident.distinctInternalRecipientCount,
      incidentDetails: incident.incidentDetails,
      isAutomaticRemediation: incident.createdByName === AUTOMATIC_REMEDIATION,
      maliciousDomains: incident.maliciousDomains || [],
      messagesReceived: incident.internalAttackCount,
      notifiedEmailCount: incident.notifiedEmailCount,
      remediatedEmailCount: incident.remediatedEmailCount,
      remediationActions: incident.remediationActions || {},
      senderEmail: incident.sender && incident.sender.email,
      senderName: incident.sender && incident.sender.displayName,
      senderPolicies: {
        blocked: incident.senderPolicies
          ? incident.senderPolicies.filter((policy: string) => policy.indexOf('block') !== -1)
          : [],
        quarantined: incident.senderPolicies
          ? incident.senderPolicies.filter((policy: string) => policy.indexOf('quarantine') !== -1)
          : []
      },
      bodyText: incident.bodyText,
      bodyLinks: incident.bodyLinks,
      subject: incident.subject,
      tags: incident.labels,
      taskStatuses: incident.taskStatuses
    } as IncidentDetailsData
  }, [incident])

  const isAutomaticRemediation = useMemo(() => {
    return incident.createdByName === AUTOMATIC_REMEDIATION
  }, [incident])

  const onViewWorkflowDetails = useCallback((workflowId: string) => {
    routesConfig.AUTOMATED_WORKFLOWS_WORKFLOW.goto({ workflowId })
  }, [])

  const pageAlertConfig = useMemo(() => {
    return {
      alertContent: state.pageError,
      closeAction: () => {
        setState({ pageError: '' })
        dispatch(resetContinuousRemediation())
      },
      pageAlert: true,
      showClose: true
    }
  }, [dispatch, state.pageError])

  const showClickedLinksTab = useMemo(() => {
    const { taskStatuses } = incident
    return taskStatuses?.clickedLinks === TASK_STATUS_COMPLETE && featureLib.hasESSFeature(accessTokenId)
  }, [accessTokenId, featureLib, incident])

  const tabsConfig = useMemo(() => {
    function filterTabs(tab: any) {
      if (!isAutomaticRemediation && tab.id === THREATS) {
        return false
      }
      if (!showClickedLinksTab && tab.id === CLICKED_LINKS) {
        return false
      }
      return true
    }

    return {
      activeTab: state.activeTab,
      tabs: TABS.filter(filterTabs).map((tab: any) => ({
        id: tab.id,
        onClick: () => {
          setState({ activeTab: tab.id })
        },
        label: formatMessage(`tabs.${tab.id}`)
      }))
    } as TabsProps
  }, [state.activeTab, isAutomaticRemediation, showClickedLinksTab, formatMessage])

  return useMemo(
    () => [
      {
        additionalActionsDialogConfig: {
          isOpened: isAdditionalActionsDialogOpened,
          onClose: () => {
            toggleAdditionalActionsDialog()
          },
          contentConfig: {
            additionalActionsOptions: {
              securityServiceLink:
                userEssRegion === 'US'
                  ? config.LINKS.BARRACUDA_ESS_LOG.US
                  : config.LINKS.BARRACUDA_ESS_LOG.NON_US.replace('REGION', userEssRegion)
            }
          }
        },
        breadcrumbList,
        handleContinuousRemediationChange,
        hasRemediationActions,
        incidentDetailsData,
        isContinuousRemediationEnabled: !!incident.continuousRemediationEnabled,
        isContinuousRemediationLoading: !!isContinuousRemediationLoading,
        isDeleteInProgress: state.isDeleteInProgress,
        isPageInProgress: isIncidentLoading,
        onViewWorkflowDetails,
        openFollowUpDialog: toggleAdditionalActionsDialog,
        pageAlertConfig,
        pageError: state.pageError,
        tabsConfig,
        tagInputConfig: {
          autocompleteTags: existingUnusedTags,
          currentTags: currentIncidentTags,
          handleTagChange
        }
      }
    ],
    [
      breadcrumbList,
      currentIncidentTags,
      existingUnusedTags,
      handleContinuousRemediationChange,
      handleTagChange,
      hasRemediationActions,
      incident.continuousRemediationEnabled,
      incidentDetailsData,
      isAdditionalActionsDialogOpened,
      isContinuousRemediationLoading,
      isIncidentLoading,
      onViewWorkflowDetails,
      pageAlertConfig,
      state.isDeleteInProgress,
      state.pageError,
      tabsConfig,
      toggleAdditionalActionsDialog,
      userEssRegion
    ]
  )
}
