import React, { useMemo } from 'react'

import { EpIncidentResponseRev } from '@barracuda-internal/bds-core/dist/Logos/Products'

import Navbar, {
  NavbarSpacer,
  NavbarContentWrapper,
  NavbarSideMenu,
  NavbarLogoBDS,
  NavbarImpersonationBanner,
  NavbarNoticableWidget,
  NavbarControlWrapper,
  NavbarAccountSwitcher,
  NavbarContextMenu,
  NavbarContextMenuO365,
  NavbarContextMenuUserInfo,
  NavbarContextMenuLogout,
  NavbarContextMenuLicense
} from 'global/components/lib/layout/navbar'

import useFirNavbarLogic from 'fir/components/lib/layout/useNavbarLogic'

const FirNavbar: React.FC = () => {
  const [firNavbarLogic] = useFirNavbarLogic()

  return useMemo(() => {
    const {
      isNavbarVisible,
      isLicenseVisible,
      navbarSideMenuProps,
      navbarImpersonationBannerProps,
      navbarNoticableWidgetProps,
      navbarAccountSwitcherProps,
      navbarContextMenuUserInfoProps,
      onLogout,
      accessTokenId,
      accounts,
      sendNavO365TrackingEvent,
      onGoToLicense
    } = firNavbarLogic

    return (
      <Navbar isNavbarVisible={isNavbarVisible}>
        <NavbarContentWrapper>
          <NavbarSideMenu {...navbarSideMenuProps} />
          <NavbarLogoBDS Logo={EpIncidentResponseRev} /> <NavbarSpacer />
          <NavbarImpersonationBanner {...navbarImpersonationBannerProps} />
          <NavbarSpacer />
          <NavbarNoticableWidget {...navbarNoticableWidgetProps} />
          <NavbarControlWrapper>
            <NavbarAccountSwitcher {...navbarAccountSwitcherProps} />
            <NavbarContextMenu>
              <NavbarContextMenuUserInfo {...navbarContextMenuUserInfoProps} />
              {isLicenseVisible && <NavbarContextMenuLicense onGoToLicense={onGoToLicense} />}
              <NavbarContextMenuO365
                accounts={accounts}
                sendNavO365TrackingEvent={sendNavO365TrackingEvent}
                {...{ accessTokenId }}
              />
              <NavbarContextMenuLogout onLogout={onLogout} />
            </NavbarContextMenu>
          </NavbarControlWrapper>
        </NavbarContentWrapper>
      </Navbar>
    )
  }, [firNavbarLogic])
}

export default FirNavbar
