import { useMemo, useReducer } from 'react'

import { config } from 'global/lib/config'
import { TabsProps } from 'global/components/lib/Tabs/Tabs'
import { useFormatMessage } from 'global/lib/localization'
import useProductLib from 'global/lib/product/useProduct'

import {
  AUTOMATIC_REMEDIATION,
  AUTOMATED_WORKFLOWS,
  MANUAL_REMEDIATION,
  POTENTIAL_INCIDENTS,
  POLICY_OPTIONS,
  SYSLOG_OPTIONS,
  USER_REPORTED
} from 'fir/components/lib/settingsTabs/SettingsTabs'

import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { resetSettingsFailures } from 'fir/redux/features/settings/settingsSlice'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseSettingsParams {}

export interface SettingsProps {
  tabsConfig: TabsProps
  settingsTabsConfig: any
}

const BASE_FIR_I18N_KEY = 'fir.app.settings.tabs'

const TABS = [
  { id: AUTOMATIC_REMEDIATION },
  { id: MANUAL_REMEDIATION },
  { id: POLICY_OPTIONS },
  { id: USER_REPORTED },
  { id: POTENTIAL_INCIDENTS },
  { id: AUTOMATED_WORKFLOWS },
  { id: SYSLOG_OPTIONS }
]

const BASIC_TABS = [{ id: AUTOMATIC_REMEDIATION }, { id: POLICY_OPTIONS }, { id: USER_REPORTED }]

export default function useSettingsLogic(params: UseSettingsParams): [SettingsProps] {
  const formatMessage = useFormatMessage(BASE_FIR_I18N_KEY)
  const dispatch = useAppDispatch()
  const [productLib] = useProductLib()

  const { accessTokenId }: any = useAppSelector((_stores: any) => ({
    accessTokenId: _stores.accessToken.accessToken?.id || ''
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    activeTab: AUTOMATIC_REMEDIATION
  })

  const tabsConfig = useMemo(() => {
    return {
      activeTab: state.activeTab,
      tabs: (productLib.getForensicsSerialBundleForAccessToken(accessTokenId) === config.BUNDLES.BUNDLE1
        ? BASIC_TABS
        : TABS
      ).map((tab: any) => ({
        id: tab.id,
        onClick: () => {
          // dismiss any error alerts
          dispatch(resetSettingsFailures())
          setState({ activeTab: tab.id })
        },
        label: formatMessage(tab.id)
      }))
    }
  }, [state.activeTab, productLib, accessTokenId, formatMessage, dispatch])

  return useMemo(
    () => [
      {
        tabsConfig,
        settingsTabsConfig: { activeSettings: state.activeTab }
      }
    ],
    [tabsConfig, state.activeTab]
  )
}
