import { useMemo, useCallback, useEffect, useReducer } from 'react'
import { snakeCase } from 'lodash'

import { NavbarNoticableWidgetProps } from 'global/components/lib/layout/navbar/noticableWidget/NavbarNoticableWidget'
import {
  NavbarSideMenuProps,
  NavbarImpersonationBannerProps,
  NavbarAccountSwitcherProps,
  NavbarContextMenuUserInfoProps,
  MenuItemProps
} from 'global/components/lib/layout/navbar'
import { config } from 'global/lib/config'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { getFirNoticeableInfo } from 'global/lib/configuration/configuration'
import useProductLib from 'global/lib/product/useProduct'
import useUserDataLib from 'global/lib/userData/useUserData'
import { useFormatMessage } from 'global/lib/localization'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import { logout } from 'global/redux/features/auth/authSlice'
import { setCurrentAccessToken, setCurrentBccAccount } from 'global/redux/features/accessToken/accessTokenSlice'
import { revertImpersonation } from 'global/redux/features/admin/adminSlice'
import { Account } from 'global/types/api/accountType'
import { AccessToken } from 'global/types/api/accessTokenType'

import { forensicsGetEssAccount } from 'fir/redux/features/remediation/remediationSlice'
import routesConfig from 'fir/lib/routes/routesConfig'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

const SIDE_MENU_ITEMS = [
  routesConfig.REMEDIATION.id,
  routesConfig.USER_REPORTED.id,
  routesConfig.INSIGHTS_AUTOMATED.id,
  routesConfig.AUTOMATED_WORKFLOWS.id,
  routesConfig.UNIFIED_REPORTING_ROOT.id,
  routesConfig.SETTINGS.id
]

const BASIC_SIDE_MENU_ITEMS = [routesConfig.REMEDIATION.id, routesConfig.USER_REPORTED.id, routesConfig.SETTINGS.id]

const SUB_ROUTES = {
  [routesConfig.REMEDIATION.id]: [routesConfig.NEW_INCIDENT.id]
}

const BASE_I18N_KEY = 'app.navbar'
const BASE_FIR_I18N_KEY = 'fir.app.sidemenu'

export type FirNavbarLogic = {
  isNavbarVisible: boolean
  isLicenseVisible: boolean
  navbarSideMenuProps: NavbarSideMenuProps
  navbarImpersonationBannerProps: NavbarImpersonationBannerProps
  navbarNoticableWidgetProps: NavbarNoticableWidgetProps
  navbarAccountSwitcherProps: NavbarAccountSwitcherProps
  navbarContextMenuUserInfoProps: NavbarContextMenuUserInfoProps
  onLogout: () => void
  accessTokenId: string
  accounts: Account[]
  sendNavO365TrackingEvent?: () => void
  onGoToLicense: () => void
}

type State = {
  listItems: string[]
  selectedMenu: string
}

export default function useFirNavbarLogic(): [FirNavbarLogic] {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageFir = useFormatMessage(BASE_FIR_I18N_KEY)
  const noticableInfo = getFirNoticeableInfo()
  const [accessTokenLib] = useAccessTokenLib()
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  const dispatch = useAppDispatch()

  const { accessToken, accessTokenId, activePathId, isNavbarVisible, splitStore, userEmail } = useAppSelector(
    _stores => ({
      accessToken: _stores.accessToken.accessToken || {},
      accessTokenId: _stores.accessToken.accessToken?.id || '',
      activePathId: _stores.app.activePath.id,
      isNavbarVisible: !!_stores.app.activePath.isNavbarVisible,
      splitStore: _stores.splitio,
      userEmail: _stores.user.data.email
    })
  )
  const [state, setState] = useReducer(
    (_state: State, newState: Partial<State>) => ({
      ..._state,
      ...newState
    }),
    {
      listItems: SIDE_MENU_ITEMS,
      selectedMenu: ''
    }
  )

  // Use the current account Id to select splitio treatment value
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])
  const isLicenseComplianceOn = useMemo(() => isMyFeatureOn(splitStore, FEATURES.IP_LICENSING_COMPLIANCE, accountId), [
    accountId,
    splitStore
  ])

  const SUB_MENU_ITEMS = {
    [routesConfig.UNIFIED_REPORTING_ROOT.id]: [
      {
        routeId: routesConfig.UNIFIED_REPORTING_ROOT.id,
        text: formatMessageFir(`${snakeCase(routesConfig.UNIFIED_REPORTING.id)}`)
      },
      {
        routeId: routesConfig.SCHEDULED_REPORTS.id,
        text: formatMessageFir(`${snakeCase(routesConfig.SCHEDULED_REPORTS.id)}`)
      }
    ]
  }

  useEffect(() => {
    setState({ selectedMenu: activePathId })
  }, [activePathId])

  useEffect(() => {
    // check if customer has basic incident response
    if (productLib.getForensicsSerialBundleForAccessToken(accessTokenId) === config.BUNDLES.BUNDLE1) {
      setState({ listItems: BASIC_SIDE_MENU_ITEMS })
    } else {
      setState({ listItems: SIDE_MENU_ITEMS })
    }
  }, [accessTokenId, productLib])

  const accounts: Account[] = useMemo(() => {
    return userDataLib.getAccounts()
  }, [userDataLib])

  const navbarSideMenuProps = useMemo<NavbarSideMenuProps>(
    () => ({
      listItems: state.listItems.map(sideMenu => ({
        disabled: productLib.hasLimitedForensicsAccess(accessTokenId),
        id: sideMenu,
        hasSubMenu: SUB_MENU_ITEMS[sideMenu] && SUB_MENU_ITEMS[sideMenu].length >= 2,
        subMenuItems: SUB_MENU_ITEMS[sideMenu],
        text: formatMessageFir(`${snakeCase(sideMenu)}`)
      })),
      onListItemSelected: (selectedMenu: string) => {
        if (selectedMenu !== state.selectedMenu) {
          setState({ selectedMenu })
          routesConfig[selectedMenu].goto()
        }
      },
      selectedIndex: state.listItems.reduce((selectedIndex: number, sideMenu: string, index: number) => {
        if ([sideMenu, ...(SUB_ROUTES[sideMenu] || [])].includes(state.selectedMenu)) {
          return index
        }

        return selectedIndex
      }, -1)
    }),
    [state.listItems, state.selectedMenu, productLib, accessTokenId, SUB_MENU_ITEMS, formatMessageFir]
  )

  const navbarImpersonationBannerProps: NavbarImpersonationBannerProps = useMemo(() => {
    return {
      shouldShowBanner: userDataLib.isImpersonationMode(),
      onRevertImpersonation: () => {
        const { v2Impersonate } = userDataLib.getUser()
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_REVERT_IMPERSONATION)
        dispatch(revertImpersonation({ v2Impersonate }))
      },
      userEmail
    }
  }, [dispatch, userEmail, userDataLib])

  const navbarNoticableWidgetProps: NavbarNoticableWidgetProps = useMemo(() => {
    return {
      noticableInfo,
      title: formatMessage('noticeable_title'),
      trackNoticeableClick: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_CLICK_NOTICEABLE)
      }
    }
  }, [formatMessage, noticableInfo])

  const navbarAccountSwitcherProps: NavbarAccountSwitcherProps = useMemo(() => {
    return {
      userDisplayName: userDataLib.getAccessTokenDisplayName(accessToken),
      menuListItems: accessTokenLib
        .getAllAccessTokens({
          hideProductTokens: [config.PRODUCTS.ETS]
        })
        .map((account: any) => ({
          id: account.id,
          name: account.name,
          displayName: account.name === 'Default' ? config.ON_PREM_DEFAULTS.NAME : account.name,
          products: account.products
        })) as MenuItemProps[],
      onAccountSwitcherButton: async (selectedAccountId: string) => {
        if (selectedAccountId !== accessTokenId) {
          await dispatch(setCurrentAccessToken(selectedAccountId))
          const validatedToken = userDataLib.getAccountByAccessToken(selectedAccountId)
          if (validatedToken) {
            dispatch(setCurrentBccAccount(validatedToken.bccId as string))
          }
          dispatch(
            forensicsGetEssAccount({
              accessTokenId: selectedAccountId
            })
          )
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_SWITCH_ACCOUNT, { selectedAccountId })

          routesConfig.REMEDIATION.goto({
            accessToken: selectedAccountId
          })
        }
      },
      currentAccessToken: accessToken as AccessToken
    }
  }, [accessToken, accessTokenId, accessTokenLib, dispatch, userDataLib])

  const navbarContextMenuUserInfoProps: NavbarContextMenuUserInfoProps = useMemo(() => {
    return {
      userEmail,
      serialNumber: productLib.getForensicsSerialNumberForAccessToken(accessTokenId),
      serialExpiry: productLib.getForensicsSerialExpiryForAccessToken(accessTokenId)
    }
  }, [accessTokenId, userEmail, productLib])

  // start logout
  const onLogout = useCallback(() => {
    dispatch(logout(true))
  }, [dispatch])

  const onGoToLicense = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_REVIEW_LICENSES)
    routesConfig.LICENSE.goto({
      accessToken: accessTokenId
    })
  }, [accessTokenId])

  const sendNavO365TrackingEvent = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_CLOUD_CONNECT)
  }, [])

  return useMemo(
    () => [
      {
        isNavbarVisible,
        isLicenseComplianceOn,
        navbarSideMenuProps,
        navbarImpersonationBannerProps,
        navbarNoticableWidgetProps,
        navbarAccountSwitcherProps,
        navbarContextMenuUserInfoProps,
        onLogout,
        onGoToLicense,
        accessTokenId,
        accounts,
        sendNavO365TrackingEvent,
        isLicenseVisible:
          !userDataLib.isMspManagedAccount(accessTokenId) &&
          isLicenseComplianceOn &&
          accessTokenLib.hasForensicsEntitlement(accessTokenId)
      }
    ],
    [
      isNavbarVisible,
      isLicenseComplianceOn,
      navbarSideMenuProps,
      navbarImpersonationBannerProps,
      navbarNoticableWidgetProps,
      navbarAccountSwitcherProps,
      navbarContextMenuUserInfoProps,
      onLogout,
      onGoToLicense,
      accessTokenId,
      accounts,
      sendNavO365TrackingEvent,
      userDataLib,
      accessTokenLib
    ]
  )
}
