import React, { useMemo } from 'react'

import { Alert } from '@barracuda-internal/bds-core'

import ContentTabSelector, { TabConfig } from 'global/components/lib/contentTabSelector/ContentTabSelector'
import { useErrorFormatMessage } from 'global/lib/localization'

import routesConfig from 'admin/lib/routes/routesConfig'
import EmailThreatScanner from 'admin/components/pages/dashboard/tabs/emailThreatScanner/EmailThreatScanner'
import ContentLayout from 'admin/components/lib/layout/ContentLayout'
import ImpersonationProtection from 'admin/components/pages/dashboard/tabs/impersonationProtection/ImpersonationProtection'
import IncidentResponse from 'admin/components/pages/dashboard/tabs/incidentResponse/IncidentResponse'
import useDashboardLogic from 'admin/components/pages/dashboard/useDashboardLogic'

const BASE_I18N_KEY = 'admin.app'

const TABS: TabConfig[] = [
  [[routesConfig.DASHBOARD_EMAIL_THREAT_SCANNER, routesConfig.DASHBOARD_ETS], 'ets_name', EmailThreatScanner],
  [
    [routesConfig.DASHBOARD_IMPERSONATION_PROTECTION, routesConfig.DASHBOARD_SENTINEL],
    'sen_name',
    ImpersonationProtection
  ],
  [[routesConfig.DASHBOARD_INCIDENT_RESPONSE, routesConfig.DASHBOARD_FORENSICS], 'fir_name', IncidentResponse]
]

const Admin: React.FC = () => {
  const [error] = useDashboardLogic()
  const errorFormatMessage = useErrorFormatMessage()

  return useMemo(
    () => (
      <>
        {error && (
          <Alert variant="filled" severity="error" data-field="error-alert">
            {errorFormatMessage('default')}
          </Alert>
        )}
        <ContentLayout data-field="content-layout">
          <ContentTabSelector
            BASE_I18N_KEY={BASE_I18N_KEY}
            tabs={TABS}
            changePageTitle
            data-field="content-tab-selector"
          />
        </ContentLayout>
      </>
    ),
    [error, errorFormatMessage]
  )
}

export default Admin
