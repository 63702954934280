import { useCallback, useReducer, useMemo } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useUserDataLib from 'global/lib/userData/useUserData'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { config } from 'global/lib/config'
import { isFailed, isPending, getErrorMessage } from 'global/redux/toolkit/api'

import { reset as resetDialog, convertETS2Sentinel } from 'ets/redux/features/settings/settingsSlice'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'

export interface FreeTrialDialogLogic {
  isFreeTrialDialogOpened: boolean
  isFreeTrialInProgress: boolean
  onFreeTrial: () => void
  onToggleFreeTrialDialog: () => void
  onUpdateCMSettings: (e: string) => void
  selectedCMSetting: string
  isDialogDisabled: boolean
  error: string | boolean
  errorMessage: string | undefined
}

export interface State {
  isConvertETS2SentinelFailed: boolean
  selectedCMSetting: string
}

export default function useFreeTrialDialogLogic(): [FreeTrialDialogLogic] {
  const dispatch = useAppDispatch()
  const {
    accessToken,
    error,
    isConvertETS2SentinelFailed,
    isFreeTrialInProgress,
    isScanLoading,
    isShareSecretSet,
    userEmail,
    errorMessage
  } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken.accessToken,
    isScanLoading:
      !!_stores.scan.stats.inProgress ||
      isPending(_stores.scan.scanStatsApiStatus) ||
      !_stores.scan.isInitialScanStatsLoaded,
    isFreeTrialInProgress: isPending(_stores.settings.convertETS2SentinelApiStatus),
    userEmail: _stores.user.data.email || '',
    isShareSecretSet: !!_stores.accessToken.shareSecret?.value,
    isConvertETS2SentinelFailed: isFailed(_stores.settings.convertETS2SentinelApiStatus),
    errorMessage: getErrorMessage(_stores.settings.convertETS2SentinelApiStatus),
    error: _stores.settings.error || isFailed(_stores.settings.convertETS2SentinelApiStatus)
  }))

  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    isConvertETS2SentinelFailed: false,
    selectedCMSetting: config.CM_SETTINGS.ENFORCE_MODE.name
  })
  const [userDataLib] = useUserDataLib()
  const [isFreeTrialDialogOpened, toggleFreeTrialDialog] = useDialogLogic()

  const onFreeTrial = useCallback(() => {
    let updatedSettings = config.CM_SETTINGS.ENFORCE_MODE.value

    if (state.selectedCMSetting === config.CM_SETTINGS.REPORTING_MODE.name) {
      updatedSettings = config.CM_SETTINGS.REPORTING_MODE.value
    }
    if (accessToken) {
      const { accountId } = userDataLib.getAccountByAccessToken(accessToken.id) || {}

      analyticsLib.trackAppEvent(analyticsLib.EVENTS.TRIAL_ACTIVATED, {
        accessToken: accessToken.id,
        settings: state.selectedCMSetting
      })

      dispatch(
        convertETS2Sentinel({ settings: updatedSettings, accessTokenId: accessToken.id, accountId, email: userEmail })
      )
    }
  }, [accessToken, dispatch, state.selectedCMSetting, userEmail, userDataLib])

  const onToggleFreeTrialDialog = useCallback(() => {
    if (!isFreeTrialInProgress) {
      toggleFreeTrialDialog()

      if (isConvertETS2SentinelFailed) {
        dispatch(resetDialog())
      }
    }
  }, [toggleFreeTrialDialog, isFreeTrialInProgress, isConvertETS2SentinelFailed, dispatch])

  const onUpdateCMSettings = useCallback(
    (selectedCMSetting: string) => {
      setState({ selectedCMSetting })
    },
    [setState]
  )

  const isButtonDisabled = useMemo(() => {
    return isScanLoading || isShareSecretSet || userDataLib.isMspManagedAccount(accessToken?.id)
  }, [isScanLoading, isShareSecretSet, accessToken, userDataLib])

  return useMemo(
    () => [
      {
        isFreeTrialDialogOpened,
        isFreeTrialInProgress,
        onFreeTrial,
        onToggleFreeTrialDialog,
        onUpdateCMSettings,
        selectedCMSetting: state.selectedCMSetting,
        isDialogDisabled: isButtonDisabled,
        error,
        errorMessage
      }
    ],
    [
      isFreeTrialDialogOpened,
      isFreeTrialInProgress,
      onFreeTrial,
      onToggleFreeTrialDialog,
      onUpdateCMSettings,
      state.selectedCMSetting,
      isButtonDisabled,
      error,
      errorMessage
    ]
  )
}
