import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { buildReportQueryFor, buildReportQueryForForensics } from 'global/redux/features/dataTables/buildQueryForTable'
import { authenticate } from 'global/lib/auth/auth'
import { Products } from 'global/types/productsType'
import { ProductAssigment, ProductLicense } from 'global/types/api/product'

import apiRoutes from 'admin/lib/api/apiRoutes'
import { RootState } from 'admin/redux/toolkit/store'

export interface ScanTotalPayload {
  scanType: 'ets' | 'sentinel'
}
export interface ImpersonatePayload {
  email: string
  product?: string
  path?: string
}
export interface DeactivatePayload {
  accountId: string
  accessTokenId: string
  email?: string
  product?: Products
}
export interface SentinelSerialNumberPayload {
  accountId: string
  accessTokenId: string
  product: Products
  serialNumber: string
}

export interface ListAccessTokensPayload {
  product: string
}

export interface GetProductAssignmentsPayload {
  accessTokenId: number
  product: Products
}
export interface GetProductLicensesPayload {
  accountId: number
  product: Products
}
export interface GetRemediationStatsPayload {
  accessTokenId: string
}

export interface GetUsersPayload {
  bccAccountId: number
}

const products = ['sentinel', 'forensics', 'scan', 'ets']

export const scanTotals = createAsyncThunk('ADMIN/scanTotals', async function doScanTotals(
  payload: ScanTotalPayload,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.SCAN_TOTALS, { data: payload })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getScans = createAsyncThunk('ADMIN/getScans', async function doGetScans(
  payload: ScanTotalPayload,
  { rejectWithValue, getState }
) {
  try {
    let query
    if (payload.scanType === 'ets') {
      query = buildReportQueryFor((getState() as RootState).dataTables.etsScans)
    } else {
      query = buildReportQueryFor((getState() as RootState).dataTables.senScans)
    }

    const resp = await restClient(apiRoutes.GET_SCANS, {
      data: { query: { ...query }, ...payload }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const impersonate = createAsyncThunk('ADMIN/impersonate', async function doImpersonate(
  payload: ImpersonatePayload,
  { rejectWithValue }
) {
  const product = payload.product || ''
  const path = payload.path || ''
  const { email } = payload

  try {
    const resp = await restClient(apiRoutes.IMPERSONATE, {
      data: { email }
    })

    let { host } = window.location

    if (product) {
      const isLocalEnv = host.includes('local')
      const fixedProduct = isLocalEnv && product === 'scan' ? 'ets' : product

      host = host.replace(new RegExp(`(${products.join('|')}){1}(.){1}`), `${fixedProduct}.`)
    }
    await authenticate(resp.data.user)
    window.location.href = `${window.location.protocol}//${host}/${path}`

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const deactivate = createAsyncThunk('ADMIN/deactivate', async function doDeactivate(
  payload: DeactivatePayload,
  { rejectWithValue }
) {
  try {
    // forensics
    let route = apiRoutes.DEACTIVATE_FORENSICS
    let redirect = '/admin/dashboard/email-threat-scanner/scans'

    // sentinel
    if (payload.product === 'sentinel') {
      route = apiRoutes.DEACTIVATE_SENTINEL
      redirect = '/admin/dashboard/impersonation-protection/accounts'
    }

    const resp = await restClient(route, {
      data: { accountId: payload.accountId, accessTokenId: payload.accessTokenId }
    })

    window.location.href = redirect

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const deactivateAndReset = createAsyncThunk('ADMIN/deactivateAndReset', async function doDeactivate(
  payload: DeactivatePayload,
  { rejectWithValue }
) {
  try {
    const route = apiRoutes.DEACTIVATE_AND_RESET

    // redirect to forensics
    let redirect = '/admin/dashboard/email-threat-scanner/scans'

    // redirect to sentinel
    if (payload.product === 'sentinel') {
      redirect = '/admin/dashboard/impersonation-protection/accounts'
    }

    const resp = await restClient(route, {
      data: { accountId: payload.accountId, accessTokenId: payload.accessTokenId, email: payload.email }
    })

    window.location.href = redirect

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const setSentinelSerialNumber = createAsyncThunk(
  'ADMIN/setSentinelSerialNumber',
  async function doSetSentinelSerialNumber(payload: SentinelSerialNumberPayload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.SET_SENTINEL_SERIAL_NUMBER, {
        data: { ...payload }
      })

      if (resp.data.subscription && resp.data.subscription.description) {
        return rejectWithValue(validateApiError(resp.data.subscription.description))
      }

      return { response: resp.data, newSerialNumber: payload.serialNumber }
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const listAccessTokens = createAsyncThunk('ADMIN/listAccessTokens', async function doListAccessTokens(
  payload: ListAccessTokensPayload,
  { rejectWithValue, getState }
) {
  try {
    const query = buildReportQueryForForensics((getState() as RootState).dataTables.firAccessTokens)

    const resp = await restClient(apiRoutes.LIST_ACCESS_TOKENS, {
      data: { query: { ...query }, ...payload }
    })

    return { report: resp.data, offset: (query.page - 1) * 10 }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getProductAssignments = createAsyncThunk(
  'ADMIN/getProductAssignments',
  async function doGetProductAssignments(payload: GetProductAssignmentsPayload, { rejectWithValue }) {
    const { accessTokenId, product } = payload

    try {
      const resp = await restClient(apiRoutes.GET_PRODUCT_ASSIGNMENTS, {
        data: { accessTokenId: accessTokenId.toString() }
      })

      return resp.data.productAssignments
        .filter((productAssignment: ProductAssigment) => {
          return productAssignment.product === product
        })
        .shift()
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getProductLicenses = createAsyncThunk('ADMIN/getProductLicenses', async function doGetProductLicenses(
  payload: GetProductLicensesPayload,
  { rejectWithValue }
) {
  const { accountId, product } = payload

  try {
    const resp = await restClient(apiRoutes.GET_PRODUCT_LICENSES, {
      data: { accountId }
    })

    return resp.data.productLicenses
      .filter((productLicense: ProductLicense) => {
        return productLicense.product === product
      })
      .shift()
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getRemediationStats = createAsyncThunk('ADMIN/getRemediationStats', async function doGetRemediationStats(
  payload: GetRemediationStatsPayload,
  { rejectWithValue }
) {
  const { accessTokenId } = payload

  try {
    const resp = await restClient(apiRoutes.GET_REMEDIATION_STATS, {
      data: { accessTokenId }
    })

    return resp.data.remediationStats
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getUsers = createAsyncThunk('ADMIN/getUsers', async function doGetUsers(
  payload: GetUsersPayload,
  { rejectWithValue }
) {
  const { bccAccountId } = payload

  try {
    const resp = await restClient(apiRoutes.GET_USERS, {
      data: { bccAccountId }
    })

    return resp.data.users
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
