import React, { useMemo } from 'react'

import { Typography, Button, Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import { config } from 'global/lib/config'
import usePermissionBannerLogic from 'global/components/lib/layout/permissionBanner/usePermissionBannerLogic'
import styles from 'global/components/lib/layout/permissionBanner/permissionBannerStyles'

const I18N_KEY = config.domainConfig.isSentinel
  ? 'sen.app.account_takeover.permission_banner'
  : 'fir.app.permission_banner'

const BASE_I18N_KEY = I18N_KEY

const PermissionBanner: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [showPermissionBanner, reconnectO365] = usePermissionBannerLogic()

  return useMemo(() => {
    if (!showPermissionBanner) {
      return null
    }

    return (
      <Grid
        className={config.domainConfig.isSentinel ? classes.senContainer : classes.firContainer}
        container
        direction="row"
      >
        <Grid item xs={10}>
          <Typography className={classes.title}>{formatMessage('title')}</Typography>
          <Typography className={classes.subtitle}>{formatMessage('subtitle')}</Typography>
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignContent="center">
            <Button className={classes.button} onClick={() => reconnectO365()} variant="contained">
              {formatMessage('button')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [showPermissionBanner, reconnectO365, classes, formatMessage])
}

export default PermissionBanner
