export enum ThreatSearchType {
  postDelivery = 'COMMUNITY',
  related = 'SIMILAR_EMAIL'
}

export interface DeleteDismissThreatCard {
  accessTokenId: string
  insightId: number
}

export interface PotentialThreat {
  accessTokenId: string
  id: number
  incidentId: string | null
  matchedEmailCount: number
  reportedDate: string
  searchCriteria: ThreatSearchCriteria
  searchType: ThreatSearchType
}

export interface ThreatSearchCriteria {
  attachmentName: string
  bodyLinks: string
  bodyText: string
  id: number
  senderEmail: string
  senderName: string
  subjectQuery: string
}
