import { useCallback, useEffect, useMemo, useReducer } from 'react'

import { getUsers, impersonate, resetGetUsers } from 'admin/redux/features/admin/adminSlice'
import { useAppDispatch, useAppSelector } from 'admin/redux/toolkit/hooks'
import { AdminUser } from 'admin/redux/types/user'
import { isPending, isSuccess } from 'global/redux/toolkit/api'

export interface ImpersonateAdminProps {
  currentUser: string
  isLoading: boolean
  onImpersonateUser: () => void
  updateImpersonatedUser: (e: any) => void
  users: AdminUser[]
}

const accessTokenProduct = 'forensics'

export default function useUserSelectDialogLogic(
  bccAccountId: number,
  createdByEmail: string
): [ImpersonateAdminProps] {
  const dispatch = useAppDispatch()
  const { adminUsers, getAdminUsersInProgress, impersonateInProgress, impersonateSuccess } = useAppSelector(
    _stores => ({
      adminUsers: _stores.admin.users,
      getAdminUsersInProgress: isPending(_stores.admin.getUsersApiStatus),
      impersonateInProgress: isPending(_stores.admin.impersonateApiStatus),
      impersonateSuccess: isSuccess(_stores.admin.impersonateApiStatus)
    })
  )
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    adminEmail: createdByEmail
  })

  // init
  useEffect(() => {
    dispatch(getUsers({ bccAccountId }))
    // unmount
    return () => {
      dispatch(resetGetUsers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onImpersonateUser = useCallback(() => {
    dispatch(impersonate({ email: state.adminEmail, product: accessTokenProduct, path: 'report' }))
  }, [dispatch, state.adminEmail])

  const updateImpersonatedUser = useCallback((e: any) => {
    setState({ adminEmail: e.target.value })
  }, [])

  return useMemo(() => {
    return [
      {
        currentUser: state.adminEmail,
        isLoading: getAdminUsersInProgress || impersonateInProgress || impersonateSuccess,
        onImpersonateUser,
        updateImpersonatedUser,
        users: adminUsers
      }
    ]
  }, [
    adminUsers,
    getAdminUsersInProgress,
    impersonateInProgress,
    impersonateSuccess,
    onImpersonateUser,
    state.adminEmail,
    updateImpersonatedUser
  ])
}
