import { makeStyles } from '@material-ui/core/styles'
import { navbarHeight } from 'global/components/lib/layout/navbar/navbarStyles'

import IMAGES from 'global/configs/theme/images.config'

const CONTENT_MAX_WIDTH = 800
const MAX_WIDTH = 350

export default makeStyles(theme => ({
  activateWrapper: {
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(3)
  },
  linearProgress: {
    position: 'fixed',
    right: 0,
    left: 0,
    top: navbarHeight,
    backgroundColor: 'transparent'
  },
  alert: {
    marginBottom: theme.spacing(1)
  },
  bold: {
    fontWeight: 'bold'
  },
  buttonSpacing: {
    minWidth: '235px',
    padding: theme.spacing(0, 5)
  },
  innerWrapper: {
    maxWidth: CONTENT_MAX_WIDTH,
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'top',
    fontFamily: '"Roboto", sans-serif',
    overflow: 'visible',
    flexDirection: 'column',
    padding: '30px 50px'
  },
  button: {
    width: '100%',
    maxWidth: MAX_WIDTH,
    margin: '30px auto 10px'
  },
  marginBottomMedium: {
    marginBottom: theme.spacing(3)
  },
  marginBottomSmall: {
    marginBottom: theme.spacing(2)
  },
  underline: {
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  footerWrapper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  footer1: {
    fontSize: 12,
    fontWeight: 400,
    color: 'white'
  },
  footer2: {
    fontSize: 12,
    fontWeight: 400,
    color: 'white',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  wrapper: {
    '-webkit-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
    background: `url('${IMAGES.startTrialOverlay}'), linear-gradient(-5deg, ${theme.colors.barracudaBlue} 45%, ${theme.colors.seagrassGreen} 100%)`,
    backgroundSize: 'cover',
    padding: theme.spacing(3, 5, 3, 10),
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
