import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Checkbox,
  CheckboxLabel,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Zoom
} from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'admin/components/lib/dialogs/deactivate/scaryDialogStyles'

export interface ScaryDialogProps {
  accessTokenId: string
  onClose: (confirm: boolean) => void
}

const BASE_I18N_KEY = 'admin.app.dialogs.scary_dialog'

export const ScaryDialog: React.FC<ScaryDialogProps> = ({ accessTokenId, onClose }) => {
  const [confirmed, setConfirmed] = useState(false)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return (
    <Dialog
      style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
      maxWidth="md"
      open
      TransitionComponent={Zoom}
      data-field="dialog"
    >
      <DialogTitle className={classes.titleWrapper} disableTypography data-field="title-wrapper">
        <Typography data-field="dialog-title" className={classes.title}>
          {formatMessage('title')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} data-field="dialog-content">
        <Typography className={classes.label} data-field="dialog-label">
          {formatMessage('label', { accessTokenId, span: (text: string) => <span key={text}>{text}</span> })}
        </Typography>
        <CheckboxLabel
          className={classes.checkbox}
          control={
            <Checkbox
              color="primary"
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
              data-field="checkbox"
              data-action="confirm"
            />
          }
          label={formatMessage('checkbox')}
          data-field="checkbox-label"
        />
      </DialogContent>
      <DialogActions className={classes.actions} data-field="dialog-actions">
        <Button
          className={classes.cancelBtn}
          variant="text"
          onClick={() => onClose(false)}
          data-action="cancel"
          data-field="cancel-btn"
        >
          {formatMessage('cancel')}
        </Button>
        <Button
          className={classes.deactivateBtn}
          variant="text"
          onClick={() => onClose(true)}
          disabled={!confirmed}
          data-action="deactivate"
          data-field="deactivate-btn"
        >
          {formatMessage('deactivate')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ScaryDialog.propTypes = {
  accessTokenId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ScaryDialog
