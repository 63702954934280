import React, { useMemo } from 'react'

import {
  Button,
  Select,
  MenuItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Zoom,
  LinearProgress
} from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import { AdminUser } from 'admin/redux/types/user'

import useUserSelectDialogLogic from './useUserSelectDialogLogic'
import styles from './userSelectDialogStyles'

export interface UserSelectDialogProps {
  bccAccountId: number
  createdByEmail: string
  onClose: () => void
}

const BASE_I18N_KEY = 'admin.app.dialogs.user_select_dialog'

export const UserSelectDialog: React.FC<UserSelectDialogProps> = ({ bccAccountId, createdByEmail, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [userSelectDialogLogic] = useUserSelectDialogLogic(bccAccountId, createdByEmail)

  return useMemo(() => {
    const { currentUser, isLoading, onImpersonateUser, updateImpersonatedUser, users } = userSelectDialogLogic

    return (
      <Dialog maxWidth="md" fullWidth open TransitionComponent={Zoom} data-field="dialog">
        <DialogContent className={classes.dialogContent} data-field="dialog-content">
          <Typography className={classes.title} data-field="dialog-title">
            {formatMessage('title')}
          </Typography>
          {isLoading && <LinearProgress data-field="loader" />}
          {!isLoading && users.length > 0 && (
            <Select value={currentUser} onChange={updateImpersonatedUser}>
              {users.map((user: AdminUser) => (
                <MenuItem value={user.email} key={user.id}>
                  <span>{user.email}</span>
                </MenuItem>
              ))}
            </Select>
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            color="secondary"
            variant="text"
            size="large"
            disabled={isLoading}
            onClick={onClose}
            data-field="dialog-dismiss-button"
          >
            &nbsp;&nbsp;{formatMessage('cancel')}&nbsp;&nbsp;
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={isLoading}
            onClick={onImpersonateUser}
            data-field="dialog-dismiss-button"
          >
            &nbsp;&nbsp;{formatMessage('impersonate')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [classes, formatMessage, onClose, userSelectDialogLogic])
}

export default UserSelectDialog
