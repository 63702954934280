/* eslint-disable no-underscore-dangle */
import { config } from 'global/lib/config'

import userDataLib from 'global/lib/userData/userData'

type ProductKeys = keyof typeof config.PRODUCTS
type Products = typeof config.PRODUCTS[ProductKeys]

export function generateProductLib() {
  /* **************** *
   * Helper functions *
   * **************** */
  function _hasProduct(accessTokenId: string, product: Products): boolean {
    return userDataLib.getProductsForAccessToken(accessTokenId).includes(product)
  }

  /* ***************** *
   * Product functions *
   * ***************** */
  function hasLimitedProductAccess(accessTokenId: string, product: Products): boolean {
    if (accessTokenId !== config.NO_TOKEN_TOKEN) {
      const at = userDataLib.getAccessTokenById(accessTokenId)
      const onPremProvider = at ? at.provider === config.CLOUD_PROVIDERS.onprem.id : true
      const canceledState =
        getProductSerialStateForAccessToken(accessTokenId, product) === config.PRODUCTS.STATES.CANCELED
      return onPremProvider || canceledState
    }
    return true
  }

  function hasMissingRequirement(accessTokenId: string): boolean {
    const onPremProvider = userDataLib.getAccessTokenById(accessTokenId)?.provider === config.CLOUD_PROVIDERS.onprem.id

    return hasETSProduct(accessTokenId as string) || onPremProvider
  }

  function hasETSProduct(accessTokenId: string): boolean {
    return _hasProduct(accessTokenId, config.PRODUCTS.ETS)
  }

  function hasForensicsProduct(accessTokenId: string): boolean {
    return _hasProduct(accessTokenId, config.PRODUCTS.FORENSICS)
  }

  function hasSentinelProduct(accessTokenId: string): boolean {
    return _hasProduct(accessTokenId, config.PRODUCTS.SENTINEL)
  }

  function hasForensicsNoSentinel(accessTokenId: string): boolean {
    return hasForensicsProduct(accessTokenId) && !hasSentinelProduct(accessTokenId)
  }

  function hasSentinelNoForensics(accessTokenId: string): boolean {
    return hasSentinelProduct(accessTokenId) && !hasForensicsProduct(accessTokenId)
  }

  function hasLimitedForensicsAccess(accessTokenId: string): boolean {
    return hasLimitedProductAccess(accessTokenId, config.PRODUCTS.FORENSICS)
  }

  function hasLimitedSentinelAccess(accessTokenId: string): boolean {
    return hasLimitedProductAccess(accessTokenId, config.PRODUCTS.SENTINEL)
  }

  /* ************************ *
   * Product serial functions *
   * ************************ */
  function getProductSerialBundleForAccessToken(accessTokenId: string, product: Products): string | undefined {
    if (userDataLib.hasUser()) {
      return userDataLib.getAccessTokenByIdNoAuth(accessTokenId)?.serialNumbers?.[product]?.bundle
    }

    return undefined
  }

  function getProductSerialExpiryForAccessToken(accessTokenId: string, product: Products): string | undefined {
    if (userDataLib.hasUser()) {
      return userDataLib.getAccessTokenByIdNoAuth(accessTokenId)?.serialNumbers?.[product]?.expiry
    }

    return undefined
  }

  function getProductSerialNumberForAccessToken(accessTokenId: string, product: Products): string | undefined {
    if (userDataLib.hasUser()) {
      return userDataLib.getAccessTokenByIdNoAuth(accessTokenId)?.serialNumbers?.[product]?.serialNumber
    }

    return undefined
  }

  function getProductSerialStateForAccessToken(accessTokenId: string, product: Products): string | undefined {
    if (userDataLib.hasUser()) {
      return userDataLib.getAccessTokenByIdNoAuth(accessTokenId)?.serialNumbers?.[product]?.state
    }

    return undefined
  }

  function getProductSerialSubstateForAccessToken(accessTokenId: string, product: Products): string | undefined {
    if (userDataLib.hasUser()) {
      return userDataLib.getAccessTokenByIdNoAuth(accessTokenId)?.serialNumbers?.[product]?.substate
    }

    return undefined
  }

  function getProductSerialCapacityForAccessToken(accessTokenId: string, product: Products): number | undefined {
    if (userDataLib.hasUser()) {
      return userDataLib.getAccessTokenByIdNoAuth(accessTokenId)?.serialNumbers?.[product]?.capacity
    }

    return undefined
  }

  /* ********************************** *
   * Incident Response serial functions *
   * ********************************** */
  function getForensicsSerialBundleForAccessToken(accessTokenId: string): string | undefined {
    return getProductSerialBundleForAccessToken(accessTokenId, config.PRODUCTS.FORENSICS)
  }

  function getForensicsSerialExpiryForAccessToken(accessTokenId: string): string | undefined {
    return getProductSerialExpiryForAccessToken(accessTokenId, config.PRODUCTS.FORENSICS)
  }

  function getForensicsSerialNumberForAccessToken(accessTokenId: string): string | undefined {
    return getProductSerialNumberForAccessToken(accessTokenId, config.PRODUCTS.FORENSICS)
  }

  function getForensicsSubstateForAccessToken(accessTokenId: string): string | undefined {
    return getProductSerialSubstateForAccessToken(accessTokenId, config.PRODUCTS.FORENSICS)
  }

  function getForensicsCapacityForAccessToken(accessTokenId: string): number | undefined {
    return getProductSerialCapacityForAccessToken(accessTokenId, config.PRODUCTS.FORENSICS)
  }

  /* ************************* *
   * Impersonation Protection serial functions *
   * ************************* */
  function getSentinelSerialBundleForAccessToken(accessTokenId: string): string | undefined {
    return getProductSerialBundleForAccessToken(accessTokenId, config.PRODUCTS.SENTINEL)
  }

  function getSentinelSerialExpiryForAccessToken(accessTokenId: string): string | undefined {
    return getProductSerialExpiryForAccessToken(accessTokenId, config.PRODUCTS.SENTINEL)
  }

  function getSentinelSerialNumberForAccessToken(accessTokenId: string): string | undefined {
    return getProductSerialNumberForAccessToken(accessTokenId, config.PRODUCTS.SENTINEL)
  }

  function getSentinelSubstateForAccessToken(accessTokenId: string): string | undefined {
    return getProductSerialSubstateForAccessToken(accessTokenId, config.PRODUCTS.SENTINEL)
  }

  function getSentinelCapacityForAccessToken(accessTokenId: string): number | undefined {
    return getProductSerialCapacityForAccessToken(accessTokenId, config.PRODUCTS.SENTINEL)
  }

  return {
    // product functions
    hasLimitedProductAccess,
    hasMissingRequirement,
    hasETSProduct,
    hasForensicsProduct,
    hasSentinelProduct,
    hasForensicsNoSentinel,
    hasSentinelNoForensics,
    hasLimitedForensicsAccess,
    hasLimitedSentinelAccess,

    // product serial functions
    getProductSerialBundleForAccessToken,
    getProductSerialExpiryForAccessToken,
    getProductSerialNumberForAccessToken,
    getProductSerialStateForAccessToken,
    getProductSerialSubstateForAccessToken,
    getProductSerialCapacityForAccessToken,

    // forensics serial functions
    getForensicsSerialBundleForAccessToken,
    getForensicsSerialExpiryForAccessToken,
    getForensicsSerialNumberForAccessToken,
    getForensicsSubstateForAccessToken,
    getForensicsCapacityForAccessToken,

    // sentinel serial functions
    getSentinelSerialBundleForAccessToken,
    getSentinelSerialExpiryForAccessToken,
    getSentinelSerialNumberForAccessToken,
    getSentinelSubstateForAccessToken,
    getSentinelCapacityForAccessToken
  }
}

export default generateProductLib()
