import React, { useMemo } from 'react'

import SettingsTabs from 'fir/components/lib/settingsTabs/SettingsTabs'
import Tabs from 'global/components/lib/Tabs/Tabs'
import useSettingsLogic, { UseSettingsParams } from './useSettingsLogic'

type SettingsProps = UseSettingsParams

const Settings: React.FC<SettingsProps> = props => {
  const [settingsLogic] = useSettingsLogic(props)

  return useMemo(() => {
    const { tabsConfig, settingsTabsConfig } = settingsLogic
    return (
      <>
        <Tabs {...tabsConfig} />
        <SettingsTabs {...settingsTabsConfig} />
      </>
    )
  }, [settingsLogic])
}

export default Settings
