import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper
  },
  navbar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    borderBottom: '1px solid #DDDDDD',
    height: theme.spacing(6),
    textAlign: 'center'
  },
  styledBadge: {
    top: 2,
    right: -16,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.info.contrastText
  },
  styledTabs: {
    overflow: 'visible',
    padding: theme.spacing(1, 5)
  }
}))
