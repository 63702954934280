import { useMemo, useEffect, useCallback } from 'react'

import { isSuccess, isPending } from 'global/redux/toolkit/api'
import * as globalO365Connect from 'global/lib/o365Connect/o365Connect'
import { config } from 'global/lib/config'
import useUserDataLib from 'global/lib/userData/useUserData'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import {
  checkO365Permissions,
  resetO365Permissions
} from 'global/redux/features/globalRemediation/globalRemediationSlice'
import { refreshO365AccessToken } from 'global/redux/features/cloud/cloudSlice'

export type ShowPermissionBanner = boolean
export type ReconnectO365 = (source?: string) => void

let interval: ReturnType<typeof setInterval>

export default function usePermissionBannerLogic(): [ShowPermissionBanner, ReconnectO365] {
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()

  const {
    o365Permissions,
    isO365PermissionsLoaded,
    isO365PermissionsInProgress,
    accessTokenId,
    isRefreshAccessTokenInProgress
  } = useAppSelector(_stores => ({
    o365Permissions: _stores.globalRemediation.o365Permissions,
    isO365PermissionsInProgress: isPending(_stores.globalRemediation.checkO365PermissionsApiStatus),
    isO365PermissionsLoaded: isSuccess(_stores.globalRemediation.checkO365PermissionsApiStatus),
    accessTokenId: _stores.accessToken.accessToken?.id,
    isRefreshAccessTokenInProgress: isPending(_stores.cloud.refreshO365AccessTokenApiStatus)
  }))

  // init
  useEffect(() => {
    if (accessTokenId) {
      dispatch(checkO365Permissions())
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      dispatch(resetO365Permissions())
      dispatch(checkO365Permissions())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenId])

  const isAllPermissionSet = useMemo(() => {
    return (
      o365Permissions.hasReadMailboxSettingsPermission &&
      o365Permissions.hasWriteMailboxSettingsPermission &&
      o365Permissions.hasReadActivityFeedPermission
    )
  }, [o365Permissions])

  // stop to check the permissions when they are all set
  useEffect(() => {
    if (isAllPermissionSet && interval) {
      clearInterval(interval)
    }
  }, [isAllPermissionSet])

  const showPermissionBanner = useMemo(() => {
    return isO365PermissionsLoaded && !isAllPermissionSet
  }, [isO365PermissionsLoaded, isAllPermissionSet])

  const connectSuccess = useCallback(() => {
    interval = setInterval(() => {
      if (!isO365PermissionsInProgress) {
        dispatch(checkO365Permissions())
      }
      if (!isRefreshAccessTokenInProgress) {
        dispatch(refreshO365AccessToken())
      }
    }, config.autoRefresh.refreshAccessTokenFreqMillis)
  }, [isO365PermissionsInProgress, isRefreshAccessTokenInProgress, dispatch])

  const reconnectO365 = useCallback(
    (source?: string) => {
      if (accessTokenId) {
        const accountId = userDataLib.getAccountByAccessToken(accessTokenId)?.accountId
        const scanType = config.domainConfig.isSentinel ? config.SCAN_TYPES.SENTINEL : config.SCAN_TYPES.FORENSICS

        let analyticsEvent
        let reconnectSource

        switch (source) {
          case config.RECONNECT_SOURCES.ATO_WIZARD:
            reconnectSource = config.RECONNECT_SOURCES.ATO_WIZARD
            analyticsEvent = analyticsLib.EVENTS.ATO_WIZARD_ADD_PERMISSIONS
            break
          case config.RECONNECT_SOURCES.ATO_RULES_PAGE:
            reconnectSource = config.RECONNECT_SOURCES.ATO_RULES_PAGE
            analyticsEvent = analyticsLib.EVENTS.INBOX_RULES_ADD_PERMISSIONS
            break
          default:
            reconnectSource = config.domainConfig.isSentinel
              ? config.RECONNECT_SOURCES.ATO_TAB
              : config.RECONNECT_SOURCES.FORENSICS_RECONNECT
            analyticsEvent = config.domainConfig.isSentinel
              ? analyticsLib.EVENTS.ATO_TAB_ADD_PERMISSIONS
              : analyticsLib.EVENTS.FIR_ADD_PERMISSIONS
            break
        }

        globalO365Connect.initReconnect({
          scanType,
          provider: config.CLOUD_PROVIDERS.office365.id,
          source: reconnectSource,
          accountId,
          accessTokenId,
          connectSuccess
        })

        analyticsLib.trackAppEvent(analyticsEvent, {
          accessTokenId
        })
      }
    },
    [accessTokenId, userDataLib, connectSuccess]
  )

  return useMemo(() => {
    return [showPermissionBanner, reconnectO365]
  }, [showPermissionBanner, reconnectO365])
}
