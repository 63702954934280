import React, { useMemo } from 'react'

import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'
import { Grid, DataTable, DataTableColumn, Paper, Typography } from '@barracuda-internal/bds-core'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import SearchField from 'global/components/lib/searchField/SearchField'
import { Cell, CellText } from 'global/components/lib/grid/cell'
import { useFormatMessage } from 'global/lib/localization'
import Pager from 'global/components/lib/grid/pager/Pager'
import { config } from 'global/lib/config'

import useAllAccountsLogic from 'admin/components/pages/dashboard/tabs/incidentResponse/tabs/allAccounts/useAllAccountsLogic'
import styles from 'admin/components/pages/dashboard/tabs/incidentResponse/tabs/allAccounts/allAccountsStyles'
import ScaryDialog from 'admin/components/lib/dialogs/deactivate/ScaryDialog'
import DeactivateDialog from 'admin/components/lib/dialogs/deactivate/DeactivateDialog'
import { ModifiedListAccessToken } from 'admin/redux/types/scans'
import TokenDialog from 'admin/components/lib/dialogs/tokenDialog/TokenDialog'
import IncidentDialog from 'admin/components/lib/dialogs/incidentDialog/IncidentDialog'
import UserSelectDialog from 'admin/components/lib/dialogs/userSelectDialog/UserSelectDialog'

const BASE_I18N_KEY = 'admin.app.fir'
const BASE_I18N_TABLE_KEY = 'admin.app.fir.table'

const AllAccounts: React.FC = () => {
  const [
    tableConfig,
    scaryDialogConfig,
    deactivateDialogConfig,
    tokenDialogConfig,
    incidentDialogConfig,
    userSelectDialogConfig
  ] = useAllAccountsLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Grid container spacing={2} data-section="accounts-table" direction="column" data-field="root-grid">
        <Grid item>
          <Paper className={classes.tableCard} elevation={0}>
            {!tableConfig.isLoaded && (
              <Grid container justifyContent="center" className={classes.progressWrapper}>
                <CircularProgress data-field="paper-loader" />
              </Grid>
            )}

            {tableConfig.isLoaded && (
              <Grid className={classes.tableWrapper} container direction="column" data-field="table-wrapper">
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <SearchField
                      {...tableConfig.searchFieldConfig}
                      placeholder={formatMessage('search_field.placeholder')}
                      data-field="search-field"
                    />
                  </Grid>
                </Grid>

                <Grid
                  className={`${classes.tableArea} ${!tableConfig.tableData.total ? 'empty-table' : ''}`}
                  data-table="accounts"
                  data-testid="accounts"
                  item
                  xs={12}
                  data-field="table-grid"
                >
                  {tableConfig.inProgress && <LinearProgress data-field="table-loader" />}
                  <DataTable
                    className={`${
                      tableConfig.tableData.total ? classes.dataTable : classes.emptyDataTable
                    } scans-table ${classes.gridLayout}`}
                    data={tableConfig.tableData}
                    {...tableConfig.pageConfig}
                    {...tableConfig.sortConfig}
                    pager={Pager}
                    data-field="table"
                  >
                    <DataTableNoRecords>
                      <Typography data-field="no-records">
                        {!tableConfig.tableData.total && tableFormatMessage('empty_table')}
                      </Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      field={tableConfig.columns.CREATED_ON}
                      {...tableConfig.columnsConfig[tableConfig.columns.CREATED_ON]}
                      title={tableFormatMessage(`${tableConfig.columns.CREATED_ON}`)}
                      cell={({ dataItem }: { dataItem: ModifiedListAccessToken }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis data-field="created-on">
                            {dataItem.createdOnFormatted}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.ADMIN}
                      {...tableConfig.columnsConfig[tableConfig.columns.ADMIN]}
                      title={tableFormatMessage(`${tableConfig.columns.ADMIN}`)}
                      cell={({ dataItem }: { dataItem: ModifiedListAccessToken }) => (
                        <Cell className={classes.cell}>
                          <CellText data-field="created-by">
                            {dataItem.createdByEmail}
                            <br />
                            <span
                              className={classes.link}
                              role="presentation"
                              data-field="impersonate-action"
                              data-action="impersonate"
                              onClick={() => {
                                tableConfig.onOpenUserSelect(dataItem)
                              }}
                            >
                              {' '}
                              {tableFormatMessage('impersonate')}
                            </span>
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.OFFICE_365_NAME}
                      {...tableConfig.columnsConfig[tableConfig.columns.OFFICE_365_NAME]}
                      title={tableFormatMessage(`${tableConfig.columns.OFFICE_365_NAME}`)}
                      cell={({ dataItem }: { dataItem: ModifiedListAccessToken }) => (
                        <Cell className={classes.cell}>
                          <CellText data-field="office-365-name">
                            {dataItem.accessTokenName}
                            <br />
                            <span
                              className={classes.link}
                              role="presentation"
                              data-action="open-deactivate"
                              onClick={() => {
                                tableConfig.onOpenScary(dataItem, false)
                              }}
                            >
                              {' '}
                              {tableFormatMessage('deactivate')}
                            </span>
                            {!config.isProd && (
                              <>
                                <br />
                                <br />
                                <span
                                  className={classes.link}
                                  role="presentation"
                                  data-action="open-deactivate"
                                  onClick={() => {
                                    tableConfig.onOpenScary(dataItem, true)
                                  }}
                                >
                                  {' '}
                                  {tableFormatMessage('deactivateReset')}
                                </span>
                              </>
                            )}
                            <br />
                            <br />
                            <span
                              className={classes.link}
                              role="presentation"
                              data-action="open-details"
                              onClick={() => {
                                tableConfig.onOpenToken(dataItem)
                              }}
                            >
                              {' '}
                              {tableFormatMessage('other_details')}
                            </span>
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={tableConfig.columns.REPORT}
                      {...tableConfig.columnsConfig[tableConfig.columns.REPORT]}
                      title={tableFormatMessage(`${tableConfig.columns.REPORT}`)}
                      cell={({ dataItem }: { dataItem: ModifiedListAccessToken }) => (
                        <Cell className={classes.cell}>
                          <CellText data-field="incident">
                            <span
                              className={classes.link}
                              role="presentation"
                              data-action="open-incident"
                              onClick={() => {
                                tableConfig.onOpenIncident(dataItem)
                              }}
                            >
                              {' '}
                              {tableFormatMessage('incidents')}
                            </span>
                          </CellText>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        {tokenDialogConfig.open && tokenDialogConfig.accessToken && (
          <TokenDialog
            accessToken={tokenDialogConfig.accessToken}
            product={tokenDialogConfig.product}
            onClose={tokenDialogConfig.onClose}
          />
        )}
        {scaryDialogConfig.open && scaryDialogConfig.accessTokenId && (
          <ScaryDialog accessTokenId={scaryDialogConfig.accessTokenId} onClose={scaryDialogConfig.onClose} />
        )}
        {deactivateDialogConfig.open && deactivateDialogConfig.accessTokenId && (
          <DeactivateDialog accessTokenId={deactivateDialogConfig.accessTokenId} />
        )}
        {incidentDialogConfig.open && incidentDialogConfig.accessTokenId && (
          <IncidentDialog accessTokenId={incidentDialogConfig.accessTokenId} onClose={incidentDialogConfig.onClose} />
        )}
        {userSelectDialogConfig.open &&
          userSelectDialogConfig.bccAccountId &&
          userSelectDialogConfig.createdByEmail && (
            <UserSelectDialog
              bccAccountId={userSelectDialogConfig.bccAccountId}
              createdByEmail={userSelectDialogConfig.createdByEmail}
              onClose={userSelectDialogConfig.onClose}
            />
          )}
      </Grid>
    ),
    [
      classes,
      tableConfig,
      formatMessage,
      tableFormatMessage,
      tokenDialogConfig,
      scaryDialogConfig,
      deactivateDialogConfig,
      incidentDialogConfig,
      userSelectDialogConfig
    ]
  )
}

export default AllAccounts
