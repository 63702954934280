import { useEffect, useCallback, useReducer, useMemo } from 'react'

import { isPending, isSuccess } from 'global/redux/toolkit/api'
import { resetError, saveProfile, reset, logout } from 'global/redux/features/auth/authSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { validateOnPremUser } from 'global/components/lib/authLayer/validateUser'
import { config } from 'global/lib/config'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'

export interface UseDomainFraudSignupLogic {
  isLoading: boolean
  onSignup: () => void
  error: string | false
}

export interface FormValues {
  [key: string]: string | undefined
  email: string
  name: string
  company: string
  phone: string
  zip: string
  country: string
}

export type IsLoading = boolean
export type FormErrors = {
  [key: string]: boolean
  hasEmptyFields: boolean
  isInvalidPhone: boolean
}
export interface Errors {
  signup: string | undefined
  form: FormErrors
}
export interface Form {
  values: FormValues
  setFormValues: (formValue: Partial<FormValues>) => void
}
export interface FormHandlers {
  onContinue: () => void
  onUseAnotherUser: () => void
}
export interface FormStates {
  isSignupButtonDisabled: boolean
}

export default function useDomainFraudSignupProfileLogic(): [IsLoading, Form, FormHandlers, FormStates, Errors] {
  const { inProgress, isSuccessSignup, authError, user } = useAppSelector(_stores => ({
    inProgress: isPending(_stores.auth.saveProfileApiStatus),
    isSuccessSignup: isSuccess(_stores.auth.saveProfileApiStatus),
    authError: _stores.auth.error,
    user: _stores.user.data
  }))
  const [formValues, setFormValues] = useReducer(
    (_state: FormValues, newState: Partial<FormValues>) => ({ ..._state, ...newState }),
    {
      email: user?.email || '',
      name: user?.displayName || '',
      company: user?.accounts?.[0]?.accountName || '',
      phone: '',
      zip: '',
      country: user?.country || ''
    }
  )
  const dispatch = useAppDispatch()
  const [accessTokenLib] = useAccessTokenLib()

  // init
  useEffect(() => {
    if (!user || validateOnPremUser(user, routesConfig) !== routesConfig.SIGNUP_PROFILE.id) {
      routesConfig.SIGNIN_SIGNUP.goto()
    }

    dispatch(reset())
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_DOMAIND_FRAUD_PROFILE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSuccessSignup) {
      const firstAccessTokenId = accessTokenLib.getAllAccessTokens()[0]?.id || ''

      routesConfig.DOMAIN_FRAUD.goto({ reportId: firstAccessTokenId })
    }
  }, [isSuccessSignup, accessTokenLib])

  // signup flow
  const onContinue = useCallback(() => {
    dispatch(resetError())

    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_SAVE_PROFILE, { url: window.location.href })

    dispatch(
      saveProfile({ ...formValues, provider: config.CLOUD_PROVIDERS.onprem.id, scanType: config.SCAN_TYPES.SENTINEL })
    )
  }, [dispatch, formValues])

  const onUseAnotherUser = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const formErrors: FormErrors = useMemo(() => {
    return {
      hasEmptyFields: Object.keys(formValues).some(formValue => !formValues[formValue]?.length),
      isInvalidPhone: !!formValues.phone?.length && !/^\d+$/.test(formValues.phone)
    }
  }, [formValues])

  const isSignupButtonDisabled = useMemo(() => {
    return Object.keys(formErrors).some(errorKey => formErrors[errorKey]) || inProgress
  }, [formErrors, inProgress])

  return useMemo(() => {
    return [
      inProgress,
      { values: formValues, setFormValues },
      {
        onContinue,
        onUseAnotherUser
      },
      { isSignupButtonDisabled },
      { signup: authError, form: formErrors }
    ]
  }, [
    inProgress,
    onContinue,
    onUseAnotherUser,
    authError,
    formValues,
    setFormValues,
    formErrors,
    isSignupButtonDisabled
  ])
}
