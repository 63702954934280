import { makeStyles } from '@material-ui/core/styles'

export const CELL_HEIGHT = 41

export default makeStyles(theme => ({
  paginationContainer: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    bottom: 0,
    zIndex: 999
  },
  paginationActions: {
    display: 'flex',
    alignItems: 'center'
  },
  paginationChip: {
    margin: theme.spacing(0.5)
  },
  paginationText: {
    fontSize: 13
  }
}))
