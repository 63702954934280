import React from 'react'

import sentintelContentLoader from 'sen/components/lib/useSentinelContentCondition/SentinelContentLoader'

import DomainFraudDmarcReviewContent from 'sen/components/pages/domainFraudDmarcReview/content/DomainFraudDmarcReviewContent'
import { DmarcReviewTabs } from 'sen/components/pages/domainFraudDmarcReview/domainFraudDmarcReviewTypes'

export default sentintelContentLoader({
  default: () => <DomainFraudDmarcReviewContent activeTab={DmarcReviewTabs.approved} />
})
