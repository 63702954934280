import React, { useMemo } from 'react'

import { Typography, Grid, Alert } from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'

import insightsLogic, { InsightsProps } from 'fir/components/pages/insights/InsightsLogic'
import CTABanner from 'fir/components/lib/ctaBanner/CTABanner'
import { POTENTIAL_INCIDENTS_CTA_COOKIE } from 'fir/components/lib/ctaBanner/useCTABannerLogic'
import { PotentialThreat } from 'fir/redux/types/Insights'

import PotentialIncidentsCard from './potentialIncidentsCard/PotentialIncidentsCard'
import usePotentialIncidentsLogic, { UsePotentialIncidentsParams } from './usePotentialIncidentsLogic'
import styles from './potentialIncidentsStyles'

type PotentialIncidentsProps = UsePotentialIncidentsParams

const BASE_I18N_KEY = 'fir.app.potential_incidents'

const PotentialIncidents: React.FC<InsightsProps & PotentialIncidentsProps> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [potentialIncidentsLogic] = usePotentialIncidentsLogic(props)

  return useMemo(() => {
    const {
      hideCTA,
      isLoading,
      onClosePageAlert,
      potentialThreats,
      showPotentialIncidentsPageError
    } = potentialIncidentsLogic

    return (
      <>
        {isLoading && <LinearProgress />}
        {!hideCTA && <CTABanner ctaCookie={POTENTIAL_INCIDENTS_CTA_COOKIE} />}
        <div className={classes.header}>
          <div>
            <Typography variant="h4" component="h1">
              {formatMessage('labels.page_title')}
            </Typography>
            <Typography variant="subtitle1">{formatMessage('labels.page_subtitle')}</Typography>
          </div>
        </div>
        {showPotentialIncidentsPageError && (
          <Alert variant="filled" severity="error" onClose={onClosePageAlert} className={classes.pageAlert}>
            {formatMessage('labels.page_error')}
          </Alert>
        )}
        <div className={classes.wrapper}>
          {!isLoading && potentialThreats.length === 0 ? (
            <div className={classes.noDataWrapper}>{formatMessage('labels.no_content')}</div>
          ) : (
            <Grid container spacing={3} className={classes.cardGrid}>
              {potentialThreats.map((threat: PotentialThreat) => (
                <PotentialIncidentsCard threat={threat} key={threat.id} />
              ))}
            </Grid>
          )}
        </div>
      </>
    )
  }, [classes, formatMessage, potentialIncidentsLogic])
}

export default insightsLogic(PotentialIncidents)
