import React, { useMemo } from 'react'

import {
  Button,
  DataTable,
  DataTableColumn,
  Typography,
  Grid,
  Paper,
  Chip,
  Tooltip
} from '@barracuda-internal/bds-core'
import Icon from '@material-ui/core/Icon'
import { Close as CloseIcon, Edit as EditIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { Cell, CellAction, CellIcon, CellText } from 'global/components/lib/grid/cell'
import IMAGES from 'global/configs/theme/images.config'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import cellsStyles from 'global/components/lib/grid/cell/cellStyles'
import gridStyles from 'global/components/lib/grid/gridStyles'
import { useFormatMessage } from 'global/lib/localization'
import Alert from 'global/components/lib/alerts/Alert'

import TopReportedDashboard from 'fir/components/pages/userReported/dashboards/TopReportersDashboard'
import CTABanner from 'fir/components/lib/ctaBanner/CTABanner'
import NewIncidentDialog from 'fir/components/lib/newIncidentDialog/NewIncidentDialog'
import { USER_REPORTED_CTA_COOKIE } from 'fir/components/lib/ctaBanner/useCTABannerLogic'

import { DISMISSED_STATE } from 'fir/redux/features/dataTables/userReported/userReportedSlice'
import useUserReportedLogic, { UserReportedProps } from 'fir/components/pages/userReported/useUserReportedLogic'
import styles from './userReportedStyles'

const BASE_I18N_KEY = 'fir.app.user_reported'

const UserReported: React.FC<UserReportedProps> = () => {
  const classes = styles()
  const gridClasses = gridStyles()
  const cellClasses = cellsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [userReportedLogic] = useUserReportedLogic()

  return useMemo(() => {
    const {
      alertConfig,
      basicIncidentResponse,
      columnsConfig,
      expandConfig,
      GRID_COLUMNS,
      gridData,
      hideCTA,
      isDismissRowLoading,
      isMoreResultsLoading,
      isPageInProgress,
      newIncidentDialogConfig,
      onCreateIncident,
      onDismissUserReported,
      onToggleViewState,
      pageConfig,
      rowsDismissed,
      showErrorAlert,
      sortConfig,
      topReportersDashboardConfig,
      viewingState
    } = userReportedLogic

    const tooltipText = (
      <div>
        <p>
          {basicIncidentResponse
            ? formatMessage('button.tooltip', { b: (txt: any) => <b key={txt}>{txt}</b> })
            : formatMessage('button.tooltip_sat_campaign', { b: (txt: any) => <b key={txt}>{txt}</b> })}
        </p>
      </div>
    )

    return (
      <>
        {newIncidentDialogConfig.isOpened && <NewIncidentDialog {...newIncidentDialogConfig} />}
        {!hideCTA && <CTABanner ctaCookie={USER_REPORTED_CTA_COOKIE} />}
        <div className={classes.header}>
          <Button
            color="secondary"
            data-action="toggleViewState"
            variant="contained"
            onClick={onToggleViewState}
            className={`${classes.floatRight} ${classes.stateButton}`}
          >
            {formatMessage(`button.view_${viewingState === DISMISSED_STATE ? 'submitted' : 'dismissed'}`)}
          </Button>
          <div>
            <Typography data-field="pageTitle" variant="h4" component="h1">
              {formatMessage(`labels.${viewingState === DISMISSED_STATE ? 'dismissed_' : ''}page_title`)}
            </Typography>
            <Typography variant="subtitle1">{formatMessage('labels.page_subtitle_1')}</Typography>
            <Typography variant="subtitle1">{formatMessage('labels.page_subtitle_2')}</Typography>
          </div>
        </div>
        {(isPageInProgress || isMoreResultsLoading || isDismissRowLoading) && <LinearProgress />}
        <Grid container className={classes.dashboards} direction="row" spacing={2}>
          <Grid xs={4} item>
            <TopReportedDashboard {...topReportersDashboardConfig} />
          </Grid>
        </Grid>
        {showErrorAlert && <Alert data-field="errorAlert" {...alertConfig} />}
        <Paper className={classes.wrapper} elevation={1}>
          <DataTable
            className={gridClasses.gridLayout}
            data-field="userReportedTable"
            pageable
            {...pageConfig}
            data={gridData}
            sortable
            {...sortConfig}
            {...expandConfig}
          >
            <DataTableColumn
              field={GRID_COLUMNS.LAST_REPORTED_DATE}
              {...columnsConfig[GRID_COLUMNS.LAST_REPORTED_DATE]}
              title={formatMessage('user_reported_table.last_reported_date')}
              cell={({ dataItem }: { dataItem: any }) => {
                return (
                  <Cell data-field="userReported-table">
                    <CellText>{dataItem.formattedDate}</CellText>
                  </Cell>
                )
              }}
            />
            <DataTableColumn
              field={GRID_COLUMNS.NUMBER_OF_USERS_REPORTED}
              {...columnsConfig[GRID_COLUMNS.NUMBER_OF_USERS_REPORTED]}
              sortable={false}
              title={formatMessage('user_reported_table.number_of_users_reported')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <Tooltip
                    title={dataItem.recipients.map((recipient: string) => (
                      <div key={recipient}>{recipient}</div>
                    ))}
                  >
                    <div>
                      <CellText>
                        <div>{dataItem.numberOfUserReported}</div>
                      </CellText>
                    </div>
                  </Tooltip>
                </Cell>
              )}
            />
            <DataTableColumn
              field={GRID_COLUMNS.SENDER_EMAIL}
              {...columnsConfig[GRID_COLUMNS.SENDER_EMAIL]}
              sortable={false}
              title={formatMessage('user_reported_table.sender_email')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.senderEmail}</CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={GRID_COLUMNS.SUBJECT}
              {...columnsConfig[GRID_COLUMNS.SUBJECT]}
              sortable={false}
              title={formatMessage('user_reported_table.subject')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>
                    {dataItem.isPhishline && (
                      <Tooltip
                        data-field="phishlineTooltip"
                        title={formatMessage('phishlineCampaignTooltip.phishline_email')}
                      >
                        <div>
                          <Chip
                            data-field="phishlineChip"
                            label={formatMessage('labels.phishline_campaign')}
                            variant="outlined"
                          />
                        </div>
                      </Tooltip>
                    )}
                    {dataItem.maliciousTooltipMessage && (
                      <Tooltip data-field="maliciousTooltip" title={dataItem.maliciousTooltipMessage}>
                        <div className={classes.warningIconContainer}>
                          <div>
                            <Icon data-field="warningIcon">
                              <img className={classes.warningIcon} alt="WARNING" src={IMAGES.iconAlertWarning} />
                            </Icon>
                          </div>
                          <div>{dataItem.subject}</div>
                        </div>
                      </Tooltip>
                    )}
                    <div>{!dataItem.maliciousTooltipMessage && dataItem.subject}</div>
                  </CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={GRID_COLUMNS.AFFECTED_MAILBOXES}
              {...columnsConfig[GRID_COLUMNS.AFFECTED_MAILBOXES]}
              title={formatMessage('user_reported_table.affected_mailboxes')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.maxMatchedCount}</CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              {...columnsConfig[GRID_COLUMNS.ACTIONS]}
              sortable={false}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <div className={cellClasses.userReportedActionsCell}>
                    <CellAction
                      data-action="createIncident"
                      className={viewingState === DISMISSED_STATE ? '' : classes.floatLeft}
                      onClick={
                        dataItem.createIncidentDisabled
                          ? null
                          : () => {
                              onCreateIncident(dataItem.searchCriteria.submittedSearchCriteria)
                            }
                      }
                    >
                      <Tooltip interactive title={tooltipText} disableHoverListener={!dataItem.createIncidentDisabled}>
                        <div>
                          <CellIcon icon={EditIcon} />
                          <CellText>{formatMessage('button.create_incident')}</CellText>
                        </div>
                      </Tooltip>
                    </CellAction>
                    {(!isDismissRowLoading || !rowsDismissed?.includes(dataItem.emailId)) &&
                      viewingState !== DISMISSED_STATE && (
                        <CellAction
                          data-action="dismissUserReported"
                          className={classes.floatRight}
                          onClick={() => {
                            onDismissUserReported(dataItem.emailId, dataItem.messageIds)
                          }}
                        >
                          <Tooltip title={formatMessage('button.dismiss')}>
                            <div>
                              <CellIcon icon={CloseIcon} />
                            </div>
                          </Tooltip>
                        </CellAction>
                      )}
                  </div>
                </Cell>
              )}
            />
          </DataTable>
        </Paper>
      </>
    )
  }, [cellClasses.userReportedActionsCell, classes, formatMessage, gridClasses.gridLayout, userReportedLogic])
}

export default UserReported
