import deepmerge from 'deepmerge'

import { makeStyles } from '@material-ui/core/styles'
import { makeGridStyles } from 'global/components/lib/grid/gridStyles'

export default makeStyles(theme => ({
  dashboards: {
    padding: theme.spacing(0, 0, 0, 2),
    width: '100%'
  },
  workflowTitleButtons: {
    float: 'right',
    margin: 6
  },
  floatLeft: {
    float: 'left'
  },
  floatRight: {
    float: 'right'
  },
  header: {
    padding: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))

export const gridStyle = {
  gridLayout: {
    '& .k-link': {
      whiteSpace: 'normal'
    },
    '& .k-grid-table': {
      wordBreak: 'break-word'
    },
    '& .k-grid-header col:nth-of-type(1)': {
      width: '10%'
    },
    '& .k-grid-table col:nth-of-type(1)': {
      width: '10%'
    },
    '& .k-grid-header col:nth-of-type(2)': {
      width: '15%'
    },
    '& .k-grid-table col:nth-of-type(2)': {
      width: '15%'
    },
    '& .k-grid-header col:nth-of-type(3)': {
      width: '15%'
    },
    '& .k-grid-table col:nth-of-type(3)': {
      width: '15%'
    },
    '& .k-grid-header col:nth-of-type(4)': {
      width: '10.5%'
    },
    '& .k-grid-table col:nth-of-type(4)': {
      width: '10.5%'
    },
    '& .k-grid-header col:nth-of-type(5)': {
      width: '10.5%'
    },
    '& .k-grid-table col:nth-of-type(5)': {
      width: '10.5%'
    },
    '& .k-grid-header col:nth-of-type(6)': {
      width: '10.5%'
    },
    '& .k-grid-table col:nth-of-type(6)': {
      width: '10.5%'
    },
    '& .k-grid-header col:nth-of-type(7)': {
      width: '10%'
    },
    '& .k-grid-table col:nth-of-type(7)': {
      width: '10%'
    },
    // Disable hover over background color
    '& .k-grid-table tr:hover': {
      background: 'transparent'
    },
    '& .k-grid-table tr.k-alt:hover': {
      background: 'transparent'
    },
    '& .k-grid-header tr:hover': {
      background: 'transparent'
    },
    '& .k-grid-header tr.k-alt:hover': {
      background: 'transparent'
    }
  }
}

export const automatedWorkflowsGridStyles = makeStyles(theme => ({
  ...deepmerge(makeGridStyles(theme), gridStyle)
}))
