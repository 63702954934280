import React from 'react'
import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'

import { BDSProvider } from '@barracuda-internal/bds-core'
import { IntlProvider } from 'react-intl'

import '@progress/kendo-theme-material/dist/all.css'

import browserHistory from 'global/lib/routes/browserHistory'
import { CustomBrowserRouter } from 'global/lib/routes/CustomBrowserRouter'
import Routes from 'fir/components/lib/routes/Routes'

import initStore from 'global/lib/reduxStore'
import initLocalization, { Localization } from 'global/lib/localization'

import appReducers from 'fir/redux/reducers'

import MESSAGES_EN from 'fir/configs/i18n/en'

const root = createRoot(document.getElementById('root')!)

export function FirApp(): void {
  const i18nConfig: Localization = initLocalization(MESSAGES_EN)

  return root.render(
    <Provider store={initStore(appReducers)}>
      <IntlProvider locale={i18nConfig.locale} messages={i18nConfig.messages}>
        <BDSProvider useBdsTheme>
          <CustomBrowserRouter history={browserHistory}>{Routes}</CustomBrowserRouter>
        </BDSProvider>
      </IntlProvider>
    </Provider>
  )
}
