import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import useUserDataLib from 'global/lib/userData/useUserData'
import { luxonDate, isAfterDate, isBeforeDate } from 'global/lib/datetime'
import * as configurationLib from 'global/lib/configuration/configuration'

import { config } from 'global/lib/config'

export enum ExpiryType {
  'freeTrial',
  'subscription'
}

export enum ExpiryStatus {
  'info' = 'info',
  'warning' = 'warning',
  'expired' = 'error'
}

export interface Expiry {
  date: string | undefined
  status?: ExpiryStatus | undefined
  type?: ExpiryType | undefined
}

const DEFAULT_EXPIRY = {
  date: undefined,
  status: undefined,
  type: undefined
}

export default function useLicenseBannerLogic(): [Expiry | undefined] {
  const { accessToken }: any = useSelector((_stores: any) => ({
    accessToken: _stores.accessToken?.accessToken
  }))
  const [userDataLib] = useUserDataLib()
  const now = luxonDate().toISO()

  /* eslint-disable react-hooks/rules-of-hooks */
  const expiry: Expiry | undefined = useMemo(() => {
    if (!accessToken) {
      return undefined
    }

    let expirationData: Expiry = { ...DEFAULT_EXPIRY }

    // do not show expiry information for MSP accounts
    if (userDataLib.isMspManagedAccount(accessToken.id)) {
      return { date: undefined }
    }

    const productId = configurationLib.getProductId()
    expirationData = {
      ...expirationData,
      date: accessToken.serialNumbers[productId]?.expiry,
      type: accessToken.serialNumbers[productId]?.serialNumber ? ExpiryType.subscription : ExpiryType.freeTrial
    }

    // TODO: remove when we want to start showing trial expiration for all trials
    if (
      expirationData.type === ExpiryType.freeTrial &&
      isBeforeDate({
        initialDate: expirationData.date,
        subtractedDate: config.TRACK_TRIAL_EXPIRATION_START_DATE
      })
    ) {
      expirationData.date = undefined
    }

    // check if expiry date passed or close
    switch (true) {
      case isAfterDate({ initialDate: now, subtractedDate: expirationData.date }):
        expirationData.status = ExpiryStatus.expired
        break
      case isAfterDate({
        initialDate: now,
        subtractedDate: luxonDate(expirationData.date as string)
          .minus({ days: config.EXPIRATION_WARNING_DAYS })
          .toISO()
      }):
        expirationData.status = ExpiryStatus.warning
        break
      case expirationData.type === ExpiryType.freeTrial:
        expirationData.status = ExpiryStatus.info
        break
      default:
        expirationData.status = undefined
    }

    return expirationData
  }, [accessToken, now, userDataLib])
  /* eslint-enable react-hooks/rules-of-hooks */

  return useMemo(() => {
    return [expiry]
  }, [expiry])
}
