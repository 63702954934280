import React, { useMemo } from 'react'

import { EpImpersonationProtectionRev } from '@barracuda-internal/bds-core/dist/Logos/Products'

import Navbar, {
  NavbarSpacer,
  NavbarLogoBDS,
  NavbarSideMenu,
  NavbarControlWrapper,
  NavbarAccountSwitcher,
  NavbarContextMenu,
  NavbarContextMenuO365,
  NavbarContextMenuUserInfo,
  NavbarContextMenuLogout,
  NavbarContextMenuLicense,
  NavbarImpersonationBanner,
  NavbarNoticableWidget,
  NavbarContextMenuManageUsers
} from 'global/components/lib/layout/navbar'

import useNavbarLogic from 'sen/components/lib/layout/useNavbarLogic'
import ManageUsersDialog from 'sen/components/lib/dialogs/manageUsersDialog/ManageUsersDialog'

const SentinelNavbar: React.FC = () => {
  const [navbarLogic] = useNavbarLogic()

  return useMemo(() => {
    const {
      isCleanupPage,
      isLicenseVisible,
      isMSP,
      isNavbarVisible,
      isOnpremUser,
      navbarNoticableWidgetProps,
      navbarAccountSwitcherProps,
      navbarContextMenuUserInfoProps,
      navbarContextMenuO365Props,
      navbarImpersonationBannerProps,
      navbarSideMenuProps,
      onGoToLicense,
      onLogout
    } = navbarLogic

    return (
      <Navbar isNavbarVisible={isNavbarVisible}>
        {!isCleanupPage && <NavbarSideMenu {...navbarSideMenuProps} />}
        <NavbarLogoBDS Logo={EpImpersonationProtectionRev} />
        <NavbarSpacer />
        <NavbarImpersonationBanner {...navbarImpersonationBannerProps} />
        <NavbarSpacer />
        <NavbarNoticableWidget {...navbarNoticableWidgetProps} />
        <NavbarControlWrapper>
          <NavbarAccountSwitcher {...navbarAccountSwitcherProps} />
          <NavbarContextMenu>
            <NavbarContextMenuUserInfo {...navbarContextMenuUserInfoProps} />
            {isLicenseVisible && <NavbarContextMenuLicense onGoToLicense={onGoToLicense} />}
            {!isCleanupPage && !isOnpremUser && <NavbarContextMenuManageUsers Dialog={ManageUsersDialog} />}
            {!isCleanupPage && (!isOnpremUser || isMSP) && <NavbarContextMenuO365 {...navbarContextMenuO365Props} />}
            <NavbarContextMenuLogout onLogout={onLogout} />
          </NavbarContextMenu>
        </NavbarControlWrapper>
      </Navbar>
    )
  }, [navbarLogic])
}

export default SentinelNavbar
