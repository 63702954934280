import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@barracuda-internal/bds-core'

import styles from 'global/components/lib/layout/navbar/navbarStyles'
import { useFormatMessage } from 'global/lib/localization'

export interface NavbarImpersonationBannerProps {
  shouldShowBanner: boolean
  onRevertImpersonation: () => void
  bannerText?: string
  buttonText?: string
  userEmail?: string
}

const BASE_I18N_KEY = 'app.navbar'

const NavbarImpersonationBanner: React.FC<NavbarImpersonationBannerProps> = ({
  onRevertImpersonation,
  shouldShowBanner,
  userEmail,
  bannerText,
  buttonText
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  if (!shouldShowBanner) {
    return null
  }

  return (
    <div className={classes.bannerWrapper}>
      <div className={classes.impersonationBanner}>
        <div className={classes.bannerText}>
          {bannerText || formatMessage('impersonation_banner', { user: userEmail })}
        </div>
        <Button
          variant="text"
          className={classes.bannerButton}
          onClick={onRevertImpersonation}
          data-action="impersonation-banner"
        >
          {buttonText || formatMessage('back')}
        </Button>
      </div>
    </div>
  )
}

NavbarImpersonationBanner.propTypes = {
  shouldShowBanner: PropTypes.bool.isRequired,
  onRevertImpersonation: PropTypes.func.isRequired,
  bannerText: PropTypes.string,
  buttonText: PropTypes.string,
  userEmail: PropTypes.string
}

NavbarImpersonationBanner.defaultProps = {
  bannerText: undefined,
  buttonText: undefined,
  userEmail: undefined
}

export default NavbarImpersonationBanner
