import React, { useMemo } from 'react'

import GlobalUnifiedReporting from 'global/components/features/unifiedReporting/UnifiedReporting'

import useUnifiedReportingChartLogic from './useUnifiedReportingChartLogic'
import useUnifiedReportingDatatableLogic from './useUnifiedReportingDatatableLogic'
import useUnifiedReportHeaderLogic from './useUnifiedReportingHeaderLogic'
import useUnifiedReportingLogic from './useUnifiedReportingLogic'
import useSideMenuLogic from './useUnifiedReportingSideMenuLogic'

const UnifiedReporting: React.FC = () => {
  const [reportingChartConfig] = useUnifiedReportingChartLogic()
  const [reportingDatatableConfig] = useUnifiedReportingDatatableLogic()
  const [reportingHeaderConfig] = useUnifiedReportHeaderLogic()
  const [unifiedReportingLogic] = useUnifiedReportingLogic()
  const [sideMenuConfig] = useSideMenuLogic()

  const { alertConfig, isLoading } = unifiedReportingLogic

  return useMemo(
    () => (
      <GlobalUnifiedReporting
        alertConfig={alertConfig}
        chartProps={reportingChartConfig}
        dataTableProps={reportingDatatableConfig}
        reportHeaderProps={reportingHeaderConfig}
        isLoading={isLoading}
        sideMenuProps={sideMenuConfig}
      />
    ),
    [alertConfig, reportingChartConfig, reportingDatatableConfig, reportingHeaderConfig, isLoading, sideMenuConfig]
  )
}

export default UnifiedReporting
