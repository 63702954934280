import React, { useMemo } from 'react'

import { Typography, Paper, Alert } from '@barracuda-internal/bds-core'
import { Assignment as AssignmentIcon, Calendar as DateRangeIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid'

import GeoChart from 'global/components/lib/charts/GeoChart'
import useDateRangePickerLogic, {
  DateRangePickerConfig,
  UseDateRangePickerLogicParams
} from 'global/components/lib/dateRangePicker/useDateRangePickerLogic'
import { Cell, CellIcon, CellText, CellImage, CellAction } from 'global/components/lib/grid/cell'
import cellsStyles from 'global/components/lib/grid/cell/cellStyles'
import gridStyles from 'global/components/lib/grid/gridStyles'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import CTABanner from 'fir/components/lib/ctaBanner/CTABanner'
import insightsLogic, { InsightsProps } from 'fir/components/pages/insights/InsightsLogic'
import { EMAILS_BY_REGION_CTA_COOKIE } from 'fir/components/lib/ctaBanner/useCTABannerLogic'

import { useFormatMessage } from 'global/lib/localization'
import useEmailsByRegionLogic, { EmailsByRegionProps } from './useEmailsByRegionLogic'
import styles from './emailsByRegionStyles'

const BASE_I18N_KEY = 'fir.app.insights.emails_by_region'

const EmailsByRegion: React.FC<InsightsProps & EmailsByRegionProps> = () => {
  const classes = styles()
  const gridClasses = gridStyles()
  const cellClasses = cellsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [emailsByRegionLogic] = useEmailsByRegionLogic()
  const [dateRangePickerLogic] = useDateRangePickerLogic(
    emailsByRegionLogic.dateRangePickerLogicConfig as UseDateRangePickerLogicParams
  )

  return useMemo(() => {
    const {
      flagSrc,
      geoChartConfig,
      gotoGeoipCountry,
      GRID_COLUMNS,
      gridData,
      hideCTA,
      isPageInProgress,
      onClosePageAlert,
      showGeoipAggregatePageError = false,
      sortConfig
    } = emailsByRegionLogic
    const { dateRangePickerConfig } = dateRangePickerLogic

    return (
      <>
        {isPageInProgress && <LinearProgress />}
        {!hideCTA && <CTABanner ctaCookie={EMAILS_BY_REGION_CTA_COOKIE} />}
        <div className={classes.header}>
          <Typography variant="h4" component="h1">
            {formatMessage('labels.page_title')}
          </Typography>
          <Typography variant="subtitle1">{formatMessage('labels.page_subtitle')}</Typography>
        </div>
        {showGeoipAggregatePageError && (
          <Alert variant="filled" severity="error" onClose={onClosePageAlert} className={classes.pageAlert}>
            {formatMessage('labels.page_error')}
          </Alert>
        )}
        <Paper className={classes.wrapper} elevation={1}>
          <div className={classes.chartHeader} />
          <div className={classes.chart}>
            <GeoChart {...geoChartConfig} />
            <div className={classes.maskLayer} />
          </div>
          <Grid className={gridClasses.gridLayout} sortable {...sortConfig} data={gridData}>
            <GridToolbar>
              <span className={gridClasses.gridToolbar}>
                <DateRangeIcon fontSize="small" className={classes.gridDateRangePickerIcon} color="secondary" />
                <DateRangePicker
                  className={classes.gridDateRangePicker}
                  allowReverse={false}
                  {...(dateRangePickerConfig as DateRangePickerConfig)}
                />
              </span>
            </GridToolbar>
            <GridColumn
              title={formatMessage('country')}
              field={GRID_COLUMNS.COUNTRY}
              cell={({ dataItem }) => (
                <Cell>
                  <CellImage src={flagSrc(dataItem.code2)} />
                  <CellText>{dataItem.country}</CellText>
                </Cell>
              )}
            />
            <GridColumn
              className={gridClasses.gridColumn}
              title={formatMessage('allowed_emails')}
              field={GRID_COLUMNS.COUNT}
            />
            <GridColumn
              headerClassName={cellClasses.reviewCell}
              cell={({ dataItem }) => (
                <Cell
                  id={`reviewButton-${dataItem.code2}`}
                  title={`${dataItem.code2}`}
                  className={cellClasses.reviewCell}
                >
                  <CellAction onClick={() => gotoGeoipCountry(dataItem.code2)}>
                    <CellIcon icon={AssignmentIcon} />
                    <CellText>{formatMessage('review')}</CellText>
                  </CellAction>
                </Cell>
              )}
            />
          </Grid>
        </Paper>
      </>
    )
  }, [cellClasses.reviewCell, classes, dateRangePickerLogic, emailsByRegionLogic, formatMessage, gridClasses])
}

export default insightsLogic(EmailsByRegion)
