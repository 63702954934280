import { useMemo, useCallback } from 'react'

import { config } from 'global/lib/config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import useUserDataLib from 'global/lib/userData/useUserData'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import useFeatureLib from 'global/lib/feature/useFeature'
import useProductLib from 'global/lib/product/useProduct'

import { logout } from 'global/redux/features/auth/authSlice'
import { revertImpersonation } from 'global/redux/features/admin/adminSlice'

import gotoReportPage from 'ets/lib/gotoReportPage'
import validateAppForAccessToken from 'ets/lib/validateAppForAccessToken'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'

import {
  NavbarContextMenuO365Props,
  MenuItemProps,
  NavbarAccountSwitcherProps,
  NavbarContextMenuUserInfoProps,
  NavbarImpersonationBannerProps
} from 'global/components/lib/layout/navbar'

export type EtsNavbarLogicProps = {
  isNavbarVisible: boolean
  navbarAccountSwitcherProps: NavbarAccountSwitcherProps
  navbarContextMenuUserInfoProps: NavbarContextMenuUserInfoProps
  navbarContextMenuO365Props: NavbarContextMenuO365Props
  navbarImpersonationBannerProps: NavbarImpersonationBannerProps
  onLogout: () => void
  isShareSecretSet: boolean
}

export default function useNavbarLogic(): [EtsNavbarLogicProps] {
  const dispatch = useAppDispatch()
  const { accessToken, userEmail, accounts, isShareSecretSet, isNavbarVisible } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken.accessToken || ({} as any),
    userEmail: _stores.user.data.email || '',
    accounts: _stores.user.data.accounts,
    isShareSecretSet: !!_stores.accessToken.shareSecret?.value,
    isNavbarVisible: _stores.app.activePath.isNavbarVisible
  }))
  const [accessTokenLib] = useAccessTokenLib()
  const [userDataLib] = useUserDataLib()
  const [featureLib] = useFeatureLib()
  const [productLib] = useProductLib()

  const navbarAccountSwitcherProps: NavbarAccountSwitcherProps = useMemo(() => {
    return {
      userDisplayName: userDataLib.getAccessTokenDisplayName(accessToken) || '',
      menuListItems: accessTokenLib.getAllAccessTokens().map((account: any) => ({
        id: account.id,
        name: account.name,
        displayName: account.name === 'Default' ? config.ON_PREM_DEFAULTS.NAME : account.name,
        products: account.products,
        bundleVersion: productLib.getSentinelSerialBundleForAccessToken(account.id)
      })) as MenuItemProps[],
      onAccountSwitcherButton: (selectedAccessTokenId: string) => {
        if (selectedAccessTokenId !== accessToken.id) {
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_SWITCH_ACCOUNT, { selectedAccessTokenId })
          validateAppForAccessToken(selectedAccessTokenId, () => {
            gotoReportPage({ accessTokenId: selectedAccessTokenId })
          })
        }
      },
      currentAccessToken: accessToken
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, accounts, dispatch, userDataLib])

  const navbarContextMenuUserInfoProps: NavbarContextMenuUserInfoProps = useMemo(() => {
    return {
      userEmail
    }
  }, [userEmail])

  const navbarContextMenuO365Props: NavbarContextMenuO365Props = useMemo(() => {
    return {
      successCb: (data: any, scanType: string) => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_CONNECT_O365_SUCCESSFUL, {
          accessTokenId: data.accessToken.id
        })
        if (scanType === config.SCAN_TYPES.SENTINEL) {
          window.location.replace(`${config.domains.sentinel}/report/${data.accessToken.id}`)
        } else {
          gotoReportPage({ accessTokenId: data.accessToken.id, user: data.user, scan: data.scan })
        }
      },
      showScanTypeSelection:
        (accounts && featureLib.hasSentinelFeatureForAnyAccessToken()) || featureLib.hasETSReadOnlyForAnyAccessToken(),
      accounts
    }
  }, [accounts, featureLib])

  const navbarImpersonationBannerProps: NavbarImpersonationBannerProps = useMemo(() => {
    return {
      shouldShowBanner: userDataLib.isImpersonationMode(),
      onRevertImpersonation: () => {
        const { v2Impersonate } = userDataLib.getUser()
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_REVERT_IMPERSONATION)
        dispatch(revertImpersonation({ v2Impersonate }))
      },
      userEmail
    }
  }, [dispatch, userEmail, userDataLib])

  // start logout
  const onLogout = useCallback(() => {
    dispatch(logout(true))
  }, [dispatch])

  return useMemo(
    () => [
      {
        isNavbarVisible: isNavbarVisible || isShareSecretSet,
        isShareSecretSet,
        navbarAccountSwitcherProps,
        navbarContextMenuUserInfoProps,
        navbarContextMenuO365Props,
        navbarImpersonationBannerProps,
        onLogout,
        accessTokenId: accessToken.id
      }
    ],
    [
      isNavbarVisible,
      isShareSecretSet,
      navbarAccountSwitcherProps,
      navbarContextMenuUserInfoProps,
      navbarContextMenuO365Props,
      navbarImpersonationBannerProps,
      onLogout,
      accessToken.id
    ]
  )
}
