import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  container: {
    border: 'none',
    boxShadow: 'none',
    marginLeft: '1px',
    padding: theme.spacing(1),
    position: 'sticky',
    top: 0,
    zIndex: 999
  },
  buttons: {
    borderRadius: '5px',
    float: 'right',
    margin: theme.spacing(1)
  },
  exportIframe: {
    display: 'none'
  },
  titleLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '23px'
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  input: {
    fontWeight: 'bold',
    fontSize: 20,
    maxWidth: 200,
    textOverflow: 'ellipsis',
    marginRight: 10
  }
}))
