import React, { useMemo } from 'react'

import { Alert, Link } from '@barracuda-internal/bds-core'
import { Build as BuildIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

const I18N_KEY = 'app.maintenance_banner'

const BASE_I18N_KEY = I18N_KEY

const MaintenanceBanner: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(() => {
    if (!config.shouldShowMaintenanceBanner) {
      return null
    }

    return (
      <Alert variant="filled" severity="error" icon={<BuildIcon fontSize="inherit" />}>
        {formatMessage('content', {
          a: (txt: any) => (
            <Link
              key="link"
              target="_blank"
              href={config.LINKS.SUPPORT}
              style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline' }}
            >
              {txt}
            </Link>
          )
        })}
      </Alert>
    )
  }, [formatMessage])
}

export default MaintenanceBanner
