import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, Alert as MaterialAlert, AlertProps as MaterialAlertProps } from '@barracuda-internal/bds-core'
import { Close as CloseIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import styles from './AlertStyles'

export interface AlertProps extends MaterialAlertProps {
  closeAction?: any
  pageAlert?: boolean
  severity?: 'error' | 'success' | 'info' | 'warning'
  showClose?: boolean
  alertContent: any
}

const Alert: React.FC<AlertProps> = ({
  className = '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeAction = () => {},
  pageAlert = false,
  severity = 'error',
  showClose = false,
  alertContent,
  ...rest
}) => {
  const classes = styles()
  return (
    <MaterialAlert
      {...rest}
      variant="filled"
      severity={severity}
      className={`${pageAlert ? classes.pageAlert : ''}${className}`}
      action={
        showClose && (
          <IconButton aria-label="close" size="small" color="inherit" onClick={closeAction}>
            <CloseIcon />
          </IconButton>
        )
      }
    >
      {alertContent}
    </MaterialAlert>
  )
}

Alert.propTypes = {
  className: PropTypes.string,
  closeAction: PropTypes.func,
  pageAlert: PropTypes.bool,
  severity: PropTypes.any,
  showClose: PropTypes.bool,
  alertContent: PropTypes.any.isRequired
}

Alert.defaultProps = {
  className: undefined,
  closeAction: undefined,
  pageAlert: false,
  severity: 'error',
  showClose: false
}

export default Alert
