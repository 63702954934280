import { useMemo, useEffect } from 'react'

import { config } from 'global/lib/config'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import { getScanStatus, reset as resetScan } from 'global/redux/features/scan/scanSlice'
import useProductLib from 'global/lib/product/useProduct'
import { isFailed, getErrorMessage } from 'global/redux/toolkit/api'
import { SettingsValue } from 'global/types/api/accessTokenType'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

export type UserEmail = string
export type ApiError = string | undefined

export interface UseSentinelContentConditionParams {
  location: Location
}

export enum UIState {
  'loading',
  'scanInProgress',
  'missedEntitlement',
  'renderContent',
  'failedToLoadScan'
}

const SCAN_STATUS_CHECK_INTERVAL = 10000

let interval: ReturnType<typeof setInterval>

export default function useSentinelContentCondition(): [UIState, UserEmail, ApiError] {
  const {
    accessTokenId,
    accessTokenSettings,
    isAccessTokenSet,
    isBootstrapInProgress,
    isScanStatusFailed,
    isScanStatusLoaded,
    scanStatusAccessTokenId,
    scanStatusError,
    userEmail
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    accessTokenSettings: _stores.accessToken.accessToken?.settings,
    isAccessTokenSet: _stores.accessToken.isAccessTokenSet,
    isBootstrapInProgress: !!_stores.scan.scanResults?.bootstrapInProgress,
    isScanStatusFailed: isFailed(_stores.scan.scanStatusApiStatus),
    isScanStatusLoaded: !!_stores.scan.stats?.id,
    scanStatusAccessTokenId: _stores.scan.scanResults?.accessTokenId,
    scanStatusError: getErrorMessage(_stores.scan.scanStatusApiStatus),
    userEmail: _stores.user.data?.email
  }))

  const dispatch = useAppDispatch()
  const [accessTokenLib] = useAccessTokenLib()
  const [productLib] = useProductLib()

  const isDPBundle = useMemo(() => {
    const bundleVersion = productLib.getSentinelSerialBundleForAccessToken(accessTokenId)
    return bundleVersion === config.BUNDLES.DP
  }, [productLib, accessTokenId])

  const hasSentinelEntitlement = useMemo(() => {
    return accessTokenLib.hasSentinelEntitlement(accessTokenId)
  }, [accessTokenId, accessTokenLib])

  // init
  useEffect(() => {
    if (isAccessTokenSet && accessTokenId !== scanStatusAccessTokenId && hasSentinelEntitlement && !isDPBundle) {
      dispatch(resetScan())
      dispatch(getScanStatus())
    }

    // unmount
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccessTokenSet])

  useEffect(() => {
    if (!isDPBundle) {
      if (isScanStatusLoaded && isBootstrapInProgress && !interval) {
        interval = setInterval(() => {
          dispatch(getScanStatus())
        }, SCAN_STATUS_CHECK_INTERVAL)
      } else if (!isBootstrapInProgress && interval) {
        clearInterval(interval)
      }
    }
  }, [dispatch, isScanStatusLoaded, isBootstrapInProgress, isDPBundle])

  const apiError: ApiError = useMemo(() => {
    return scanStatusError
  }, [scanStatusError])

  const uiState: UIState = useMemo(() => {
    switch (true) {
      case isScanStatusFailed:
        return UIState.failedToLoadScan
      case hasSentinelEntitlement && !isScanStatusLoaded && !isScanStatusFailed && !isDPBundle:
        return UIState.loading
      case !hasSentinelEntitlement:
        return UIState.missedEntitlement
      // Check that the scan is loaded and that either the bootstrap is in progress OR the sentinel onboarding process
      // is in queue (i.e. waiting for IR onboarding to complete before starting the scan)
      case isScanStatusLoaded &&
        (isBootstrapInProgress || accessTokenSettings?.SentinelOnboardingInQueue === SettingsValue.on):
        return UIState.scanInProgress
      default:
        return UIState.renderContent
    }
  }, [
    isScanStatusFailed,
    hasSentinelEntitlement,
    isScanStatusLoaded,
    isDPBundle,
    isBootstrapInProgress,
    accessTokenSettings
  ])

  return useMemo(() => {
    return [uiState, userEmail || '', apiError]
  }, [uiState, userEmail, apiError])
}
