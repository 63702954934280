import React from 'react'
import PropTypes from 'prop-types'

import { Box, Tab, Tabs } from '@barracuda-internal/bds-core'

import styles from './tabsStyles'

export interface TabsProps {
  activeTab: any
  tabs: TabType[]
}

export interface TabType {
  id: string | number
  label: any
  onClick: (e: MouseEvent) => void
}

export const idProp = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

export const tabsProps: any = {
  activeTab: idProp.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.exact({
      id: idProp,
      label: PropTypes.any,
      onClick: PropTypes.func
    })
  ).isRequired
}

const TabsComponent: React.FC<TabsProps> = ({ activeTab, tabs }) => {
  const classes = styles()

  return (
    <div className={classes.root}>
      <Box className={classes.navbar}>
        <Tabs value={activeTab} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="on">
          {tabs.map((tab: TabType) => (
            <Tab
              className={classes.styledTabs}
              data-field={tab.id}
              key={tab.id}
              onClick={tab.onClick}
              label={tab.label}
              value={tab.id}
            />
          ))}
        </Tabs>
      </Box>
    </div>
  )
}

TabsComponent.propTypes = tabsProps

export default TabsComponent
