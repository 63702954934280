import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

export default makeStyles(theme => {
  const text = {
    fontSize: 16,
    color: COLORS.RED,
    lineHeight: '16px'
  }

  return {
    firContainer: {
      borderWidth: 2,
      borderRadius: theme.spacing(1),
      borderColor: COLORS.RED,
      borderStyle: 'solid',
      maxWidth: '99%',
      marginInline: 'auto',
      marginBottom: '16px',
      marginTop: '16px',
      padding: theme.spacing(3)
    },
    senContainer: {
      borderWidth: 2,
      borderRadius: theme.spacing(1),
      borderColor: COLORS.RED,
      borderStyle: 'solid',
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
      padding: theme.spacing(3)
    },
    title: text,
    subtitle: {
      ...text,
      fontSize: 12
    },
    button: {
      color: theme.palette.info.contrastText,
      backgroundColor: COLORS.RED,

      '&:hover': {
        color: theme.palette.info.contrastText,
        backgroundColor: COLORS.RED
      }
    }
  }
})
