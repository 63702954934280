import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Button, DataTable, DataTableColumn, Paper, Typography, Grid, Tooltip } from '@barracuda-internal/bds-core'
import {
  Assignment as AssignmentIcon,
  Edit as EditIcon,
  Pause,
  PlayArrow
} from '@barracuda-internal/bds-core/dist/Icons/Core'

import Alert from 'global/components/lib/alerts/Alert'
import TextFilter from 'global/components/lib/grid/filter/TextFilter'
import { Cell, CellAction, CellIcon, CellText } from 'global/components/lib/grid/cell'
import cellsStyles from 'global/components/lib/grid/cell/cellStyles'
import ColumnMenu from 'global/components/lib/grid/columnMenu/ColumnMenu'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import TopWorkflowRunsDashboard from 'fir/components/pages/automatedWorkflows/dashboards/TopWorkflowRunsDashboard'
import TopWorkflowActionsDashboard from 'fir/components/pages/automatedWorkflows/dashboards/TopWorkflowActionsDashboard'
import WorkflowRunsDashboard from 'fir/components/pages/automatedWorkflows/dashboards/WorkflowRunsDashboard'

import { DISABLED_STATE } from 'fir/redux/features/dataTables/automatedWorkflows/automatedWorkflowsSlice'

import { useFormatMessage } from 'global/lib/localization'
import useAutomatedWorkflowsLogic, { UseAutomatedWorkflowsParams } from './useAutomatedWorkflowsLogic'
import styles, { automatedWorkflowsGridStyles } from './automatedWorkflowsStyles'

import useTopWorkflowRunsDashboardLogic from './dashboards/useTopWorkflowsDashboardLogic'
import useWorkflowRunsDashboardLogic from './dashboards/useWorkflowRunsDashboardLogic'

import WorkflowDialog from './workflowDialog/WorkflowDialog'

type AutomatedWorkflowsProps = UseAutomatedWorkflowsParams

const BASE_I18N_KEY = 'fir.app.automated_workflows'

const AutomatedWorkflows: React.FC<AutomatedWorkflowsProps> = props => {
  const classes = styles()
  const cellClasses = cellsStyles()
  const gridClasses = automatedWorkflowsGridStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const intl = useIntl()
  const [automatedWorkflowLogic] = useAutomatedWorkflowsLogic(props)
  const [workflowRunsDashboardLogic] = useWorkflowRunsDashboardLogic()
  const [topWorkflowRunsDashboardLogic] = useTopWorkflowRunsDashboardLogic()

  return useMemo(() => {
    const {
      alertConfig,
      columnsConfig,
      columnMenuConfig,
      GRID_COLUMNS,
      filterConfig,
      gotoWorkflowView,
      gridData,
      isColumnActive,
      isGetWorkflowsLoading,
      onToggleViewState,
      onWorkflowStatusChange,
      openWorkflowDialog,
      pageConfig,
      showErrorAlert,
      sortConfig,
      viewState,
      workflowDialogConfig
    } = automatedWorkflowLogic
    const { topTriggeredWorkflowsConfig, topWorkflowActionsConfig } = topWorkflowRunsDashboardLogic
    const { workflowRunsDashboardConfig } = workflowRunsDashboardLogic
    return (
      <>
        {workflowDialogConfig.isOpened && <WorkflowDialog {...workflowDialogConfig} />}
        <div className={classes.header}>
          <Button
            className={classes.workflowTitleButtons}
            onClick={() => {
              openWorkflowDialog()
            }}
            color="primary"
            variant="contained"
            data-action="create-workflow"
          >
            {formatMessage('buttons.create_workflow')}
          </Button>
          <Button
            className={classes.workflowTitleButtons}
            color="secondary"
            variant="contained"
            onClick={onToggleViewState}
            data-action="toggle-workflow-status"
          >
            {formatMessage(`buttons.view_${viewState === DISABLED_STATE ? 'enabled' : 'disabled'}`)}
          </Button>
          <div>
            <Typography variant="h4" component="h1">
              {formatMessage(`labels.${viewState === DISABLED_STATE ? 'disabled_' : ''}page_title`)}
            </Typography>
            <Typography variant="subtitle1">{formatMessage('labels.page_subtitle')}</Typography>
          </div>
        </div>
        {showErrorAlert && <Alert {...alertConfig} />}
        <Grid container className={classes.dashboards} direction="row" spacing={2}>
          <Grid xs={4} item>
            <WorkflowRunsDashboard {...workflowRunsDashboardConfig} />
          </Grid>
          <Grid xs={4} item>
            <TopWorkflowRunsDashboard {...topTriggeredWorkflowsConfig} />
          </Grid>
          <Grid xs={4} item>
            <TopWorkflowActionsDashboard {...topWorkflowActionsConfig} />
          </Grid>
        </Grid>
        <Paper className={classes.wrapper} elevation={1}>
          {isGetWorkflowsLoading && <LinearProgress />}
          <DataTable
            className={gridClasses.gridLayout}
            data={gridData}
            pageable
            sortable
            {...filterConfig}
            {...pageConfig}
            {...sortConfig}
          >
            <DataTableColumn
              {...columnsConfig[GRID_COLUMNS.CREATED_ON]}
              field={GRID_COLUMNS.CREATED_ON}
              title={formatMessage('automated_workflow_table.created_on')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.formatedDate}</CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={GRID_COLUMNS.WORKFLOW_NAME}
              filter="text"
              filterable
              headerClassName={isColumnActive(GRID_COLUMNS.WORKFLOW_NAME) ? 'active' : ''}
              title={formatMessage('automated_workflow_table.workflow_name')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.name}</CellText>
                </Cell>
              )}
              columnMenu={columnProps => (
                <ColumnMenu
                  {...columnProps}
                  {...columnMenuConfig}
                  filterUI={({ firstFilterProps }: any) => (
                    <TextFilter
                      intl={intl}
                      firstFilterProps={firstFilterProps}
                      operators={['KOTLIN_CONTAINS', 'KOTLIN_DOES_NOT_CONTAIN']}
                    />
                  )}
                />
              )}
            />
            <DataTableColumn
              field={GRID_COLUMNS.EDITED_BY}
              filter="text"
              filterable
              headerClassName={isColumnActive(GRID_COLUMNS.EDITED_BY) ? 'active' : ''}
              title={formatMessage('automated_workflow_table.edited_by')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.editor}</CellText>
                </Cell>
              )}
              columnMenu={columnProps => (
                <ColumnMenu
                  {...columnProps}
                  {...columnMenuConfig}
                  filterUI={({ firstFilterProps }: any) => (
                    <TextFilter
                      intl={intl}
                      firstFilterProps={firstFilterProps}
                      operators={['KOTLIN_CONTAINS', 'KOTLIN_DOES_NOT_CONTAIN']}
                    />
                  )}
                />
              )}
            />
            <DataTableColumn
              field={GRID_COLUMNS.TIMES_TRIGGERED}
              sortable={false}
              title={formatMessage('automated_workflow_table.times_triggered')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.numberOfTimesTriggered}</CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={GRID_COLUMNS.CONDITIONS_CHECKED}
              sortable={false}
              title={formatMessage('automated_workflow_table.conditions_checked')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.numberOfConditionsChecked}</CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={GRID_COLUMNS.ACTIONS_TAKEN}
              sortable={false}
              title={formatMessage('automated_workflow_table.actions_taken')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.numberOfActionsTaken}</CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              sortable={false}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <div className={cellClasses.workflowActionsCell}>
                    <CellAction
                      onClick={() => {
                        openWorkflowDialog(dataItem.workflowUUID)
                      }}
                    >
                      <Tooltip title={formatMessage('buttons.tooltips.edit_workflow')}>
                        <div>
                          <CellIcon icon={EditIcon} />
                        </div>
                      </Tooltip>
                    </CellAction>
                    <CellAction className={classes.floatRight} onClick={() => gotoWorkflowView(dataItem.workflowUUID)}>
                      <Tooltip title={formatMessage('buttons.tooltips.view_workflow')}>
                        <div>
                          <CellIcon icon={AssignmentIcon} />
                        </div>
                      </Tooltip>
                    </CellAction>
                    <CellAction onClick={() => onWorkflowStatusChange(dataItem.workflowUUID)}>
                      <Tooltip
                        title={formatMessage(
                          `buttons.tooltips.${viewState === DISABLED_STATE ? 'enable' : 'disable'}_workflow`
                        )}
                      >
                        <div>
                          <CellIcon icon={viewState === DISABLED_STATE ? PlayArrow : Pause} />
                        </div>
                      </Tooltip>
                    </CellAction>
                  </div>
                </Cell>
              )}
            />
          </DataTable>
        </Paper>
      </>
    )
  }, [
    automatedWorkflowLogic,
    topWorkflowRunsDashboardLogic,
    workflowRunsDashboardLogic,
    classes.header,
    classes.workflowTitleButtons,
    classes.dashboards,
    classes.wrapper,
    classes.floatRight,
    formatMessage,
    gridClasses.gridLayout,
    intl,
    cellClasses.workflowActionsCell
  ])
}

export default AutomatedWorkflows
