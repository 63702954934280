import deepmerge from 'deepmerge'

import { makeStyles } from '@material-ui/core/styles'

import { makeGridStyles } from 'global/components/lib/grid/gridStyles'

export default makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  createIncidentButton: {
    float: 'right',
    margin: 6
  },
  dashboards: {
    padding: theme.spacing(0, 0, 0, 2),
    width: '100%'
  },
  header: {
    padding: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  root: {
    display: 'block',
    position: 'relative'
  },
  settings: {
    float: 'right'
  },
  tags: {
    marginRight: theme.spacing(0.5)
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))

export const gridStyle = {
  gridLayout: {
    '& td': {
      wordBreak: 'break-word'
    },
    '& .k-grid-header col:nth-of-type(1)': {
      width: '14%'
    },
    '& .k-grid-table col:nth-of-type(1)': {
      width: '14%'
    },
    '& .k-grid-header col:nth-of-type(2)': {
      width: '15%'
    },
    '& .k-grid-table col:nth-of-type(2)': {
      width: '15%'
    },
    '& .k-grid-header col:nth-of-type(3)': {
      width: '25%'
    },
    '& .k-grid-table col:nth-of-type(3)': {
      width: '25%'
    },
    '& .k-grid-header col:nth-of-type(4)': {
      width: '9%'
    },
    '& .k-grid-table col:nth-of-type(4)': {
      width: '9%'
    },
    '& .k-grid-header col:nth-of-type(6)': {
      width: '15%'
    },
    '& .k-grid-table col:nth-of-type(6)': {
      width: '15%'
    },
    '& .k-grid-header col:nth-of-type(7)': {
      width: '13%'
    },
    '& .k-grid-table col:nth-of-type(7)': {
      width: '13%'
    }
  }
}

export const incidentGridStyle = makeStyles(theme => ({ ...deepmerge(makeGridStyles(theme), gridStyle) }))
