import { useEffect, useCallback, useMemo, useReducer, useContext } from 'react'

import { config } from 'global/lib/config'
import { Account, SerialInformation } from 'global/types/api/accountType'

import { useAppSelector, useAppDispatch } from 'global/redux/toolkit/hooks'
import useUserDataLib from 'global/lib/userData/useUserData'
import * as globalO365Connect from 'global/lib/o365Connect/o365Connect'
import { NavbarContextMenuContext } from 'global/components/lib/layout/navbar/contextMenu/NavbarContextMenu'
import { getSerialWithAccountId } from 'global/redux/features/account/accountSlice'
import { isSuccess } from 'global/redux/toolkit/api'

export interface ConnectO365DialogLogicProps {
  toggleDialog: () => void
  successCb: (data: any, scanType: string) => void
  accessTokenId?: string
  isDialogOpened: boolean
  showScanTypeSelection?: boolean
  accounts?: Account[]
}

export interface ConnectO365DialogLogic {
  onClickConnect: () => void
  onCloseAlert: () => void
  onPanelSelect: (panelId: string) => void
  connectError: string
  dataPrivacyLink: string
  multiStepConfig: {
    isActive: boolean
    accounts: Account[]
    onSetActiveStep: (nextStep: number) => void
    activeStep: number
    onAccountChange: (e: any) => void
    selectedAccountId: string | undefined
  }
  selectedPanel: string
  showBCCAccountSelectOverride: boolean
  showConnectError: boolean
  showEchoError: boolean
  subtitleText: string
}

export interface State {
  activeStep: number
  connectError: string
  selectedAccountId: string | undefined
  selectedPanel: string
  showConnectError: boolean
}

export default function useConnectO365Dialog({
  toggleDialog,
  successCb,
  accessTokenId,
  showScanTypeSelection,
  accounts = []
}: ConnectO365DialogLogicProps): [ConnectO365DialogLogic] {
  const navbarContextMenuContext = useContext(NavbarContextMenuContext)
  const { accountStore } = useAppSelector(_stores => ({
    accountStore: _stores.account
  }))
  const dispatch = useAppDispatch()
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    activeStep: 0,
    connectError: 'default',
    selectedAccountId: accounts.length ? accounts[0].accountId : undefined,
    selectedPanel: config.SCAN_TYPES.SENTINEL,
    showConnectError: false
  })
  const [userDataLib] = useUserDataLib()

  const isFIR = useMemo(() => {
    return config.domainConfig.isForensics
  }, [])

  const isETS = useMemo(() => {
    return config.domainConfig.isEts
  }, [])

  const isSEN = useMemo(() => {
    return config.domainConfig.isSentinel
  }, [])

  // init - get sentinel serial information for each account
  useEffect(() => {
    if (isETS || isSEN) {
      const accountIds = accounts.map((account: Account) => account.accountId)
      dispatch(getSerialWithAccountId({ product: config.PRODUCTS.SENTINEL, accountIds }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // validate sentinel serialnumbers
  const isValidSerial = useCallback(() => {
    // get current account info from selected account
    const currentAccountSerialInfo =
      isSuccess(accountStore.getSerialWithAccountIdApiStatus) &&
      // TODO: Validate accountStore.serialInformation type
      (accountStore.serialInformation as any).find(
        (serialInfo: SerialInformation) => serialInfo.accountId === state.selectedAccountId
      )
    return currentAccountSerialInfo?.serialNumber && !['C', 'D'].includes(currentAccountSerialInfo.state)
  }, [accountStore.getSerialWithAccountIdApiStatus, state.selectedAccountId, accountStore.serialInformation])

  // show error and disable connect if account is MSP enabled and does not have a valid sentinel serialnumber
  const showEchoError = useMemo(() => {
    return (
      (isETS || isSEN) &&
      state.selectedPanel === config.SCAN_TYPES.SENTINEL &&
      !!userDataLib.getAccountByAccountId(state.selectedAccountId)?.mspManaged &&
      !isValidSerial()
    )
  }, [state.selectedPanel, state.selectedAccountId, isValidSerial, userDataLib, isETS, isSEN])

  const onCloseAlert = useCallback(() => {
    setState({ showConnectError: false })
  }, [])

  const onPanelSelect = useCallback((panelId: string) => {
    setState({ selectedPanel: panelId })
  }, [])

  const scanType = useMemo(() => {
    switch (true) {
      case (isETS || isSEN) && showScanTypeSelection:
        return state.selectedPanel
      case isSEN && !showScanTypeSelection:
        return config.SCAN_TYPES.SENTINEL
      case isFIR:
        return config.SCAN_TYPES.FORENSICS
      default:
        return config.SCAN_TYPES.ETS
    }
  }, [state.selectedPanel, isETS, showScanTypeSelection, isFIR, isSEN])

  const onClickConnect = useCallback(() => {
    // Fetch the accountId
    const accountId =
      state.selectedAccountId ||
      userDataLib.getAccountByAccessToken(accessTokenId)?.accountId ||
      (userDataLib.getAccounts() || [{}])[0].accountId

    if (accountId) {
      globalO365Connect.init({
        scanType,
        provider: config.CLOUD_PROVIDERS.office365.id,
        source: config.SCAN_SOURCES.NAVBAR,
        accountId,
        connectSuccess: data => {
          navbarContextMenuContext.closeMenu()
          toggleDialog()
          onCloseAlert()
          successCb(data, scanType)
        },
        authFailure: (data: any) => {
          setState({
            showConnectError: true,
            connectError: `connect_o365_dialog.${globalO365Connect.authFailureError(data)}`
          })
        }
      })
    } else {
      setState({ showConnectError: true })
    }
  }, [
    accessTokenId,
    toggleDialog,
    onCloseAlert,
    successCb,
    navbarContextMenuContext,
    scanType,
    state.selectedAccountId,
    userDataLib
  ])

  const dataPrivacyLink = useMemo(() => {
    if (isFIR) {
      return `${config.domains.forensics}${config.ASSETS.BASE_FILES_PATH}${config.ASSETS.FORENSICS.DATA_PRIVACY}`
    }

    return `${config.domains.ets}${config.ASSETS.BASE_FILES_PATH}${config.ASSETS.ETS.DATA_PRIVACY}`
  }, [isFIR])

  const subtitleText = useMemo(() => {
    switch (true) {
      case (isETS || isSEN) && showScanTypeSelection:
        return 'connect_o365_selection'
      case isFIR:
        return 'connect_o365'
      default:
        return 'generate_report'
    }
  }, [isETS, isFIR, isSEN, showScanTypeSelection])

  const multiStepConfig = useMemo(() => {
    return {
      isActive: !isFIR && accounts.length > 1,
      accounts,
      onSetActiveStep: (nextStep: number) => {
        setState({ activeStep: nextStep })
      },
      activeStep: state.activeStep,
      selectedAccountId: state.selectedAccountId,
      onAccountChange: (e: any) => {
        setState({ selectedAccountId: e.target.value })
      }
    }
  }, [accounts, isFIR, state.activeStep, state.selectedAccountId])

  return useMemo(
    () => [
      {
        connectError: state.connectError,
        dataPrivacyLink,
        multiStepConfig,
        onClickConnect,
        onCloseAlert,
        onPanelSelect,
        selectedPanel: state.selectedPanel,
        showBCCAccountSelectOverride: isFIR && accounts.length > 1,
        showConnectError: state.showConnectError,
        showEchoError,
        subtitleText
      }
    ],
    [
      state,
      dataPrivacyLink,
      multiStepConfig,
      onClickConnect,
      onCloseAlert,
      onPanelSelect,
      isFIR,
      accounts,
      showEchoError,
      subtitleText
    ]
  )
}
